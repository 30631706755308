import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useImperativeHandle,
  MutableRefObject,
} from "react";
import {
  Form,
  Button,
  Grid,
  Input,
  Confirm,
  Icon,
  Modal,
  Radio,
  Checkbox,
} from "semantic-ui-react";

import ReactTable from "react-table-6";
import { Bar } from "react-chartjs-2";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { toast } from "react-toastify";
import moment from "moment";
import $ from "jquery";

// Common
import CardLayout from "react-lib/apps/common/CardLayout";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import TimeTextBox from "react-lib/apps/common/TimeTextBox";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";
import ModInfo from "react-lib/apps/common/ModInfo";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ComboBox from "react-lib/apps/common/ComboBox";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// CSS
import "react-table-hoc-fixed-columns/lib/styles.css";
import "react-lib/css/ReactTableFixedColumns.css";

// Utils
import { formatUTCtoBEString, timeStrBEToStringAD } from "react-lib/utils/dateUtils";
import { formatDate } from "react-lib/utils/dateUtils";
import { adToBe, beToAd } from "react-lib/utils";
import { useIntl } from "react-intl";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const TABLE_LIMIT = 99999;
const CHART_LIMIT = 10;

const NUMBER_LENGTH = 6;
const RESULT_TYPE_INT = "INT";
const RESULT_TYPE_DECIMAL = "DECIMAL";
const DUPLICATE_SAVE_TRESHOLD = 1; // Minutes of mark duplication save

// const TYPE_WEIGHT = 3;
// const TYPE_HEIGHT = 2;
// const TYPE_BMI = 1;

const VITALSIGN_CODE_BMI = "BMI";
const VITALSIGN_CODE_WEIGHT = "Weight";
const VITALSIGN_CODE_HEIGHT = "Height";

const CardVitalSign = React.forwardRef(
  (
    props: {
      closeable?: boolean;
      toggleable?: boolean;
      callOnDidMount?: boolean;
      showBottomLeftForm?: boolean;
      readOnly?: boolean;
      useUTCTime?: boolean;
      hideBarChart?: boolean;
      hideFilter?: boolean;
      hideFooter?: boolean;
      cardStyle?: object;
      hideHeaderIcon?: boolean;
      headerColor?: string;

      // Function
      onGetLastHeight?: any;
      onGetVitalSignType?: any;
      onGetVitalSign?: any;
      hideCallback?: any;
      onNewMeasurement?: any;
      onUpdateVitalsignValue?: any;
      onDeleteMeasurement?: any;
      useCheckboxDateManual?: boolean;
      vitalSignTypeByAge?: number;
      vitalSignTypeByEncounter?: number;
      setProp?: any;
      buttonLoadCheck?: any;
      defaultDateFilter?: boolean;
    },
    ref
  ) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showModInfo, setShowModInfo] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [alertTitleText, setAlertTitleText] = useState("");
    const [alertTitleColor, setAlertTitleColor] = useState("yellow");
    const [isShowDuplicateDialog, setIsShowDuplicateDialog] = useState(false);
    const [messageDupDialog, setMessageDupDialog] = useState("");
    const [vitalSignType, setVitalSignType] = useState<any[]>([]);
    const [vitalSign, setVitalSign] = useState<any[]>([]);
    const [nextUrl, setNextUrl] = useState(null);
    const [prevUrl, setPrevUrl] = useState(null);

    const [offset, setOffset] = useState("0");
    const [tableColumnData, setTableColumnData] = useState<any>([]);
    const [tableRowData, setTableRowData] = useState<any>([]);
    const [vitalSignGraphData, setVitalSignGraph] = useState<any[]>([]);
    const [chkDateSearch, setChkDateSearch] = useState(false);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [chkTimeSearch, setChkTimeSearch] = useState(false);
    const [startTime, setStartTime] = useState<string | null>("");
    const [endTime, setEndTime] = useState<string | null>("");
    const [grapLabel, setChartLabel] = useState<any[]>([]);
    const [chkSave, setChkSave] = useState(false);
    const [saveDate, setSaveDate] = useState(formatDate(new Date()));
    const [saveTime, setSaveTime] = useState("");
    const [rowSelected, setRowSelected] = useState<any>({});
    const [selectedCell, setSelectedCell] = useState<any>({});
    const [originalTableRowData, setOriginalTableRowData] = useState<any>([]);
    const [authmodalOpen, setAuthModalOpen] = useState(false);
    const [delMeasureId, setDeleteMeasureId] = useState(null);
    const [authErrorsMessage, setAuthErrorsMessage] = useState(null);
    const [cancelUser, setCancelUser] = useState("");
    const [cancelPassword, setCancelPassword] = useState("");
    const [lastHeight, setLastHeight] = useState("");

    const [skipIndex, setSkipIndex] = useState(0);
    const [width, setWidth] = useState<any>("");
    const [init, setInit] = useState(false);

    const [dropdownVitalSignType, setDropdownVitalSignType] = useState("");
    const [radioCheckedDate, setRadioCheckedDate] = useState<any>("current");

    const myRef = useRef<any>(null);
    const formRef = useRef<any>(null);
    const isMounted = useRef(false);
    const inputMeasuredValueRef = useRef<any>();
    const divRef = useRef<HTMLDivElement | null>(null);
    const rightRef = useRef<HTMLDivElement | null>(null);
    const intl = useIntl();

    useImperativeHandle(ref, () => ({
      onValidateDuplicationSave: () => {
        validateDuplicationSave();
      },
    }));

    useEffect(() => {
      isMounted.current = true;
      console.log("CardVitalSign mount")
      if (props.defaultDateFilter) {
        console.log("default filter ")
        const momentDate = moment();

        setChkDateSearch(true);
        setStartDate(formatDate(momentDate.clone().add(-6, "days")));
        setEndDate(formatDate(momentDate));
      }
      setInit(true);
      return () => {
        isMounted.current = false;
      };
    }, []);

    useEffect(() => {
      console.log("CardVitalSign useEffect ")
      if (!init) {
        console.log("CardVitalSign not init")
        return;
      }

      if (props.vitalSignTypeByEncounter) {
        console.log("CardVitalSign call get VitalSign")
        setSaveTime(moment().format("HH:mm"));
        setSaveDate(formatDate(new Date()));
        getVitalSign(TABLE_LIMIT, 0);
      } else {
        console.log("CardVitalSign not have vitalSignTypeByEncounter")
      }
    }, [init, props.vitalSignTypeByEncounter]);

    useEffect(() => {
      const handleResize = () => {
        if (!(rightRef.current && divRef.current)) {
          return;
        }

        const rightEdge = rightRef.current.getBoundingClientRect().right;
        const leftEdge = divRef.current.getBoundingClientRect().left;
        const formWidth = formRef.current.clientWidth;

        const elementsGap = rightEdge - leftEdge;
        const finalWidth = elementsGap < formWidth ? formWidth + 30 : elementsGap;

        setWidth(finalWidth);
      };

      const resizeObserver = new ResizeObserver(() => {
        try {
          // Use requestAnimationFrame to avoid sync updates
          requestAnimationFrame(() => {
            handleResize();
          });
        } catch (error) {
          console.error("ResizeObserver error:", error);
        }
      });

      if (divRef.current?.parentElement) {
        resizeObserver.observe(divRef.current.parentElement);
      } else {
        console.error("Element to observe not found");
      }
    }, []);

    useEffect(() => {
      createTableHeader();
      createTableRow();

      if (vitalSign) {
        // createChartLabel();
        createGraphData();
      } else {
        // setTableColumnData([])
        // setTableRowData([])
        setVitalSignGraph([]);
        setChkDateSearch(false);
        setStartDate(null);
        setEndDate(null);
        setChkTimeSearch(false);
        setStartTime(null);
        setEndTime(null);
        setChartLabel([]);
        setSkipIndex(0);
      }
    }, [vitalSign]);

    useEffect(() => {
      createTableHeader();

      const timer = setTimeout(() => {
        scrollToRight();
      }, 2000);
      return () => clearTimeout(timer);
    }, [selectedCell, originalTableRowData, tableRowData]);

    useEffect(() => {
      setCancelUser("");
      setCancelPassword("");
    }, [authmodalOpen]);

    const subChartData = useMemo(() => {
      const length = grapLabel.length;
      const index = length > CHART_LIMIT ? length - CHART_LIMIT + skipIndex : 0;

      return {
        labels: [...grapLabel].slice(index, CHART_LIMIT),
        datasets: vitalSignGraphData.map((chart) => ({
          ...chart,
          // data:
          //   chart.data.length > CHART_LIMIT
          //     ? [...grapLabel].slice(index, CHART_LIMIT)
          //     : chart.data,
        })),
        // labels: grapLabel,
        // datasets: vitalSignGraphData,
        isNext: skipIndex !== 0,
        isPrev: index !== 0 && length > CHART_LIMIT,
      };
    }, [skipIndex, vitalSignGraphData, grapLabel]);

    function compare_vitalsign_obj(arr1: any, original: any) {
      var arr1Copy = JSON.parse(JSON.stringify(arr1));

      for (var i = 0; i < original.length; i++) {
        // console.log(arr1Copy[i]);

        // delete same value in each object
        for (const [key, value] of Object.entries(arr1Copy[i])) {
          // console.log(key, value, original[i][key]);
          if (key === "newVitalSign" && arr1Copy[i].code === VITALSIGN_CODE_WEIGHT) {
            if (arr1Copy[i][key] === "NA") {
              delete arr1Copy[i][key];
            }
          } else if (key === "newVitalSign" && arr1Copy[i].code === VITALSIGN_CODE_HEIGHT) {
            if (arr1Copy[i][key] === "NA") {
              delete arr1Copy[i][key];
            }
          } else if (JSON.stringify(original[i][key]) === JSON.stringify(value)) {
            delete arr1Copy[i][key];
          }
        }
        arr1Copy[i]["meta"] = {};
        arr1Copy[i]["meta"]["name"] = original[i]["name"];
        arr1Copy[i]["meta"]["vitalsign_type"] = original[i]["vitalsign_type"];
      }

      return arr1Copy;
    }

    const validateData = (value: any, type: any) => {
      if (type == RESULT_TYPE_DECIMAL || type == RESULT_TYPE_INT) {
        if (isNaN(Number(value))) {
          setAlertTitleColor("red");
          setAlertText("");
          setAlertTitleText("กรุณาระบุค่าที่เป็นตัวเลข");
          setShowModInfo(true);
          return false;
        }
        if (Number(value) < 0) {
          setAlertTitleColor("red");
          setAlertText("");
          setAlertTitleText("ไม่สามารถระบุเป็นจำนวนติดลบได้");
          setShowModInfo(true);
          return false;
        }
        var exceedLength = value
          .split(".")
          .concat([""])
          .reduce(function (a: any, b: any) {
            if (a.length > NUMBER_LENGTH || b.length > NUMBER_LENGTH) {
              return true;
            } else {
              return false;
            }
          });
        if (exceedLength) {
          setAlertTitleColor("red");
          setAlertText("");
          setAlertTitleText("ไม่สามารถระบุจำนวนเกิน " + NUMBER_LENGTH + " หลักได้");
          setShowModInfo(true);
          return false;
        }
        // if(type == RESULT_TYPE_INT && Number(value) % 1 !== 0){
        //     modInfo.titleName = 'ต้องระบุเป็นจำนวนเต็ม'
        //     modInfo.titleColor = 'red'
        //     modInfo.txtContent = ''
        //     modInfo.show()
        //     return false
        // }
      }
      return true;
    };

    const getBMI = (newTableRowData: any, isNew: any, measurement_id: any) => {
      // Calculate BMI
      const weights = newTableRowData.find((member: any) => {
        return member.code == VITALSIGN_CODE_WEIGHT;
      });
      const heights = newTableRowData.find((member: any) => {
        return member.code == VITALSIGN_CODE_HEIGHT;
      });

      var weight = null;
      var height = null;
      if (isNew) {
        weight = parseFloat(weights.newVitalSign);
        height = parseFloat(heights.newVitalSign);
      } else {
        weight = parseFloat(weights[measurement_id].formatted_string);
        height = parseFloat(heights[measurement_id].formatted_string);
      }

      var bmi = calBMI(weight, height);
      return bmi;
    };

    const calBMI = (weight: any, height: any) => {
      height /= 100;
      var bmi: any = weight / (height * height);
      bmi = isFinite(bmi) ? parseFloat(bmi).toFixed(2) : "";

      return bmi;
    };

    const handleOpen = () => {
      setAuthModalOpen(true);
    };

    const handleSubmitVerify = async () => {
      setIsLoading(true);
      setAuthErrorsMessage(null);
      var params: any = {};
      params.username = cancelUser;
      params.password = cancelPassword;
      params.active = false;
      params.measurement_id = delMeasureId;

      const errors = await props.onDeleteMeasurement(delMeasureId, params);

      if (isMounted.current) {
        if (errors) {
          setIsLoading(false);
          console.log(errors);
          setAuthErrorsMessage(errors);
        } else {
          getVitalSign(TABLE_LIMIT, 0);
          setAuthModalOpen(false);
          setIsLoading(false);
          setDeleteMeasureId(null);
        }
      }
    };

    const handleModVerifyUserCancel = () => {
      setAuthModalOpen(false);
    };

    const dismissDupDialog = () => {
      setIsShowDuplicateDialog(false);
    };

    const validateDuplicationSave = () => {
      var showPopup = false;

      tableRowData.forEach(function iterator(item: any) {
        for (const [key, value] of Object.entries(item)) {
          // key (BE)
          let keyAD = timeStrBEToStringAD(key);
          let lastSaveDateAD = moment(keyAD, "DD-MM-YYYY hh:mm");
          if (lastSaveDateAD.isValid()) {
            if (moment().diff(lastSaveDateAD, "minutes") < DUPLICATE_SAVE_TRESHOLD) {
              showPopup = true;
            }
          }
        }
      });

      if (showPopup) {
        var txt = intl.formatMessage({ id: "มีข้อมูล Vital Sign ถูกบันทึกไปแล้วในระบบภายในระยะเวลา" })
        txt += DUPLICATE_SAVE_TRESHOLD;
        txt += intl.formatMessage({ id: "นาที ต้องการบันทึกข้อมูลซ้ำหรือไม่" })
        setMessageDupDialog(txt);
        setIsShowDuplicateDialog(true);
      } else {
        saveVitalSign();
      }
    };

    const isNewVitalSignUpdate = (vital_signs: any) => {
      for (var i = 0; i < vital_signs.length; i++) {
        if (
          vital_signs[i].code !== VITALSIGN_CODE_HEIGHT &&
          vital_signs[i].code !== VITALSIGN_CODE_WEIGHT
        ) {
          return true;
        }
      }
      return false;
    };

    const isMultiMeasureUpdate = (update_list: any) => {
      var firstMeasureId = null;
      for (var i = 0; i < update_list.length; i++) {
        if (i === 0) {
          firstMeasureId = update_list[i].measurement;
        }

        if (firstMeasureId !== update_list[i].measurement) {
          return true;
        }
      }
      return false;
    };

    const saveVitalSign = async () => {
      setIsLoading(true);
      console.log(vitalSign);
      console.log(tableRowData);
      console.log(originalTableRowData);

      var isModified = !(JSON.stringify(tableRowData) === JSON.stringify(originalTableRowData));
      var diff = isModified ? compare_vitalsign_obj(tableRowData, originalTableRowData) : [];

      console.log("isModified: ", isModified);
      console.log("diff: ", diff);

      var vital_signs: any[] = [];
      var params: any = {};
      //{items: [{vitalsign_type: 1, result: "59.0", measurement: 336371, id: 2654441}]}
      var update_list: any[] = [];
      var update_params: any = {};
      if (radioCheckedDate === "manual") {
        params.date = saveDate;

        if (props.useUTCTime) {
          params.time = saveTime;
        } else {
          if (parseInt(saveTime.split(":")[0]) <= 6) {
            params.date = beToAd(params.date).subtract(moment.duration({ days: 1 }));
            params.date = adToBe(params.date, "YYYY-MM-DD");
          }
          params.time = moment(saveTime, "HH:mm")
            .subtract(moment.duration({ hour: 7, minutes: 0 }))
            .format("HH:mm");
        }
      } else {
        params.date = null;
        params.time = null;
      }
      params.encounter = vitalSign[0] === undefined ? null : vitalSign[0]?.encounter;

      diff.forEach(function iterator(item: any) {
        for (const [key, value] of Object.entries(item) as any) {
          if (key != "meta") {
            if (key === "newVitalSign") {
              var newMeasure: any = {};
              newMeasure.vitalsign_type = item["meta"]["vitalsign_type"];
              newMeasure.result = value;
              vital_signs.push(newMeasure);
            } else {
              console.log("Update:", item["meta"], key, value);
              // update previous result
              var updateMeasure: any = {};
              updateMeasure.id = value.id;
              updateMeasure.vitalsign_type = item["meta"]["vitalsign_type"];
              updateMeasure.result = value.formatted_string;
              updateMeasure.measurement = value.measurement_id;
              update_list.push(updateMeasure);
            }
          }
        }
      });

      if (props.showBottomLeftForm && inputMeasuredValueRef.current) {
        const value = inputMeasuredValueRef.current.value;
        if (value && dropdownVitalSignType) {
          if (radioCheckedDate === "current") {
            vital_signs.push({
              vitalsign_type: dropdownVitalSignType,
              result: value,
            });
          }
          if (radioCheckedDate === "last") {
            const findVitalSign = originalTableRowData.find(
              (row: any) => row.vitalsign_type === dropdownVitalSignType
            );
            const last = Object.keys(findVitalSign)
              .reverse()
              .filter((key) => {
                return +key;
              });
            const data = findVitalSign[last[0]];
            if (data) {
              update_list.push({
                id: data.id,
                vitalsign_type: dropdownVitalSignType,
                result: value,
                measurement: data.measurement_id,
              });
            } else {
              vital_signs.push({
                vitalsign_type: dropdownVitalSignType,
                result: value,
              });
            }
          }
          if (radioCheckedDate === "manual") {
            let measurementTarget = vitalSign.find(
              (item) => item.date === params.date && item.time === params.time
            );
            if (measurementTarget) {
              let vitalSignId =
                measurementTarget.vital_signs.find(
                  (i: any) => i.vitalsign_type === dropdownVitalSignType
                )?.id || null;
              update_list.push({
                id: vitalSignId,
                vitalsign_type: dropdownVitalSignType,
                result: value,
                measurement: measurementTarget.id,
              });
            } else {
              vital_signs.push({
                vitalsign_type: dropdownVitalSignType,
                result: value,
              });
            }
          }
        }
        inputMeasuredValueRef.current.value = "";
        setRadioCheckedDate("current");
        setDropdownVitalSignType("");
      }

      if (radioCheckedDate === "manual") {
        if (params.date && params.time) {
          let measurementTarget = vitalSign.find(
            (item) => item.date === params.date && item.time === params.time
          );
          if (measurementTarget) {
            vital_signs.forEach((item) => {
              let vitalSignId =
                measurementTarget.vital_signs.find(
                  (i: any) => i.vitalsign_type === item.vitalsign_type
                )?.id || null;
              update_list.push({
                ...item,
                id: vitalSignId,
                measurement: measurementTarget.id,
              });
            });
            vital_signs = [];
          }
        }
      }

      // if input the last column without date and select choice "last"
      if (radioCheckedDate === "last" && vitalSign.length > 0) {
        let lastMeasurement = vitalSign.reverse()[0];
        console.log("Last Measurement:", lastMeasurement);
        vital_signs.forEach((item) => {
          let idMeasurement =
            lastMeasurement.vital_signs.find((i: any) => i.vitalsign_type === item.vitalsign_type)
              ?.id || null;
          update_list.push({
            ...item,
            id: idMeasurement,
            measurement: lastMeasurement.id,
          });
        });
        vital_signs = [];
      }

      console.log("vital sign data: ", vital_signs, update_list);

      if (isMultiMeasureUpdate(update_list)) {
        setIsLoading(false);
        setAlertTitleColor("yellow");
        setAlertText("ไม่สามารถแก้ไขหลาย measurement ในครั้งเดียวได้");
        setAlertTitleText("-");
        setShowModInfo(true);
        return;
      }

      var fetchReq = false;
      console.log(vital_signs);
      if (isNewVitalSignUpdate(vital_signs)) {
        params.vital_signs = vital_signs;
        console.log(params);
        const errors = await props.onNewMeasurement(params); //เรียกจริง
        if (isMounted.current) {
          if (errors) {
            console.log("vitalsign errors:", errors);
            setAlertTitleColor("red");
            setAlertText(errors);
            setAlertTitleText("เกิดข้อผิดพลาด");
            setShowModInfo(true);
          }
          fetchReq = true;
        } else {
          return;
        }
      }
      console.log(tableRowData, originalTableRowData, "56");
      if (update_list.length > 0) {
        update_params.items = update_list;
        console.log(update_params);
        const update_errors = await props.onUpdateVitalsignValue(update_params); //เรียกจริง
        if (isMounted.current) {
          console.log(update_errors);
          if (update_errors) {
            setAlertTitleColor("red");
            setAlertText(update_errors);
            setAlertTitleText("เกิดข้อผิดพลาด");
            setShowModInfo(true);
          }
          fetchReq = true;
        } else {
          return;
        }
      }

      if (isMounted.current) {
        if (fetchReq) {
          getVitalSign(TABLE_LIMIT, 0);
          setIsShowDuplicateDialog(false);
        }
        setIsLoading(false);
      }
    };

    const getVitalSign = async (limit: number, offset: number) => {
      setIsLoading(true);

      let [lastHeightData, lastHeightError] = await props.onGetLastHeight();
      if (!isMounted.current) {
        console.log("getVitalSign isMounted.current", isMounted.current)
        return;
      }
      if (lastHeightError) {
        console.log('getVitalSign lastHeightError: ', lastHeightError);
        toast.error(lastHeightError);
      } else if (lastHeightData && isMounted.current) {
        setLastHeight(lastHeightData);
        console.log("setLastHeight...");
      }

      let [vitalSignTypeData, vitalSignTypeError, network] = await props.onGetVitalSignType();
      if (!isMounted.current) { 
        console.log("getVitalSign isMounted.current", isMounted.current)
        return;
      }
      console.log(vitalSignTypeData, "TYPE");
      if (vitalSignTypeError) {
        console.log("getVitalSign vitalSignTypeError", vitalSignTypeError)
        toast.error(vitalSignTypeError);
      } else if (vitalSignTypeData && isMounted.current) {
        setVitalSignType(vitalSignTypeData.items);
      }

      console.log("Call onGetVitalSign")
      let [vitalSignData, vitalSignError] = await props.onGetVitalSign({
        ...{ limit, offset },
        ...(chkDateSearch ? { startDate, endDate } : {}),
        ...(chkTimeSearch ? { startTime, endTime } : {}),
      });
      
      console.log('getVitalSign vitalSignError: ', vitalSignError);
      console.log('getVitalSign vitalSignData: ', vitalSignData);
      if (!isMounted.current) { 
        console.log("getVitalSign isMounted.current", isMounted.current)
        return;
      }
      if (vitalSignError) {
        toast.error(vitalSignError);
      } else if (vitalSignData) {
        if (typeof props.vitalSignTypeByEncounter === "number" && !!vitalSignData?.items?.length) {
          if (chkDateSearch || chkTimeSearch) {
            vitalSignData.items = vitalSignData.items;
          } else {
            vitalSignData.items = vitalSignData.items.filter(
              (item: any) => item.encounter === props.vitalSignTypeByEncounter
            );
          }
        }

        setNextUrl(vitalSignData.next);
        setPrevUrl(vitalSignData.previous);
        if (vitalSign.length <= 0 || offset <= 0) {
          setVitalSign(vitalSignData.items.reverse());
        } else {
          var appendVitalSing = vitalSignData.items.reverse().concat(vitalSign);
          setVitalSign(appendVitalSing);
        }
      }
      setIsLoading(false);
    };

    const handleStepChange = (type: "left" | "right") => () => {
      setSkipIndex(type === "left" ? skipIndex - 1 : skipIndex + 1);
    };

    const scrollToRight = () => {
      var rtTable: any = $("." + myRef.current.uniqClassName + " > .rt-table").first();
      var rtTBody: any = $("." + myRef.current.uniqClassName + " .rt-tbody").first();
      rtTable.scrollLeft(rtTBody.width() - rtTable.width());
    };

    const createGraphData = () => {
      const graphShow = vitalSignType.filter((items: any) => items.graph_shown === true);

      let graphShowList = graphShow.map((items: any) => items.name);

      var graphDataSet: any[] = [];
      let chartLabel: any[] = [];

      const vitalSignList = !vitalSign?.[0]
        ? [{ vital_signs: filterVitalSignTypeByAge(graphShow) }]
        : vitalSign;

      for (const vitalSignDataItem of vitalSignList) {
        for (const vitalSignItem of vitalSignDataItem.vital_signs) {
          if (vitalSignItem.formatted_string === "") {
            continue;
          }

          let dateTime = vitalSignDataItem.date
            ? `${vitalSignDataItem.date}\n${moment(vitalSignDataItem.date_time_utc).format(
                "HH:mm"
              )}`
            : "";
          if (props.useUTCTime && vitalSignDataItem.date_time_utc) {
            const [dateComponent, timeComponent] = formatUTCtoBEString(
              vitalSignDataItem.date_time_utc
            );
            dateTime = `${dateComponent}\n${timeComponent}`;
          }

          let graphData = [{ x: dateTime, y: vitalSignItem.formatted_string }];
          if (!graphShowList.includes(vitalSignItem.name)) {
            continue;
          }
          let sameField = graphDataSet.find((obj: any) => obj.origin_name === vitalSignItem.name);

          if (sameField) {
            let diastolic = vitalSignDataItem.vital_signs.find(
              (items: any) => items.name === "Diastolic"
            );
            const dupCount = sameField.data.filter(
              (item: any) => item.x.substring(0, dateTime.length) === dateTime
            ).length;
            dateTime = dateTime + (dupCount === 0 ? "" : `(${dupCount})`);
            if (vitalSignItem.name === "Systolic") {
              if (diastolic) {
                sameField.data.push({
                  x: dateTime,
                  y:
                    parseInt(vitalSignItem.formatted_string) - parseInt(diastolic.formatted_string),
                });
              }
            } else {
              sameField.data.push({
                x: dateTime,
                y: graphData[0].y,
              });
            }
          } else {
            let label = vitalSignItem.name + "(" + vitalSignItem.unit + ")";
            let lineColor = vitalSignType.find(
              (obj: any) => obj.name === vitalSignItem.name
            )?.graph_color;
            if (vitalSignItem.name === "Systolic") {
              let diastolic = vitalSignDataItem.vital_signs.find(
                (items: any) => items.name === "Diastolic"
              );
              if (diastolic) {
                label = vitalSignItem.name + "/" + diastolic.name + "(" + vitalSignItem.unit + ")";
                graphData = [
                  {
                    x: dateTime,
                    y:
                      parseInt(vitalSignItem.formatted_string) -
                      parseInt(diastolic.formatted_string),
                  },
                ];
              }
            }

            sameField = {
              label: label,
              origin_name: vitalSignItem.name,
              type: ["Systolic", "Diastolic"].includes(vitalSignItem.name) ? "bar" : "line",
              stack: ["Systolic", "Diastolic"].includes(vitalSignItem.name) ? "Stack 0" : null,
              fill: false,
              backgroundColor:
                vitalSignItem.name === "Diastolic" ? "rgba(255, 255, 255, 0.1)" : lineColor,
              borderColor:
                vitalSignItem.name === "Diastolic" ? "rgba(255, 255, 255, 0.1)" : lineColor,
              showLine: true,
              data: graphData || [],
            };
            let systolic = graphDataSet.find((items: any) => items.label.includes("Systolic"));
            let systolicIndex = 0;
            if (systolic) {
              systolicIndex = graphDataSet.findIndex((item: any) => item.label === systolic.label);
              graphDataSet.splice(systolicIndex, 0, sameField);
            } else {
              graphDataSet.push(sameField);
            }
          }
          if (!chartLabel.includes(dateTime)) {
            chartLabel.push(dateTime);
          }
        }
      }

      setVitalSignGraph(graphDataSet);
      setChartLabel(chartLabel);
      setSkipIndex(0);
    };

    const createTableHeader = () => {
      let headerColumn: any[] = [
        {
          Header: "Vital Sign",
          accessor: "name",
          fixed: "left",
          resizable: false,
          width: 200,
        },
      ];
      vitalSign.map((vitalSignDataItem, index) => {
        let dateTime = `${vitalSignDataItem.date} ${moment(vitalSignDataItem.date_time_utc).format(
          "HH:mm"
        )}`;
        if (props.useUTCTime && vitalSignDataItem.date_time_utc) {
          const [dateComponent, timeComponent] = formatUTCtoBEString(
            vitalSignDataItem.date_time_utc
          );
          dateTime = `${dateComponent} ${timeComponent}`;
        }
        let measurement_id: any = vitalSignDataItem.id + "";
        headerColumn.push({
          // Header: dateTime,
          Header: (props: any) => {
            return (
              <div id={`VitalSign-SaveItem-${index}`}>
                <label>{dateTime}</label>
                <Icon
                  id={`VitalSign-SaveItem-Cancel-${index}`}
                  name="cancel"
                  inverted
                  color="red"
                  link={true}
                  onClick={async () => {
                    setDeleteMeasureId(measurement_id);
                    handleOpen();
                  }}
                />
              </div>
            );
          },
          accessor: measurement_id,
          className: "noPadding",
          resizable: false,
          width: 90,
          getProps: (state: any, rowInfo: any, column: any) => {
            if (rowInfo) {
              var origiRowData = originalTableRowData[rowInfo.index];
              if (origiRowData && origiRowData[measurement_id]) {
                if (
                  origiRowData[measurement_id]?.formatted_string !==
                  rowInfo.original[measurement_id]?.formatted_string
                ) {
                  return {
                    style: { background: "#FEFFC8" },
                  };
                }
              }
            }
            return {
              style: { background: "#FFFFFF" },
            };
          },
          Cell: (props: any) => {
            let index = tableRowData.findIndex((items: any) => items.name === props.original.name);
            let newTableRowData = [...tableRowData];
            const preValidate = () => {
              if (props.original[measurement_id].formatted_string.length === 0) {
                if (index > -1) {
                  newTableRowData[index][measurement_id] = {
                    ...newTableRowData[index][measurement_id],
                    formatted_string: "NA",
                  };
                  setTableRowData(newTableRowData);
                }
              } else {
                var validate_result = validateData(
                  props.original[measurement_id].formatted_string,
                  props.original.result_type
                );
                if (!validate_result) {
                  newTableRowData[index][measurement_id] = {
                    ...newTableRowData[index][measurement_id],
                    formatted_string: originalTableRowData[index][measurement_id].formatted_string,
                  };
                  setTableRowData(newTableRowData);
                }
              }
              if (
                [VITALSIGN_CODE_BMI, VITALSIGN_CODE_HEIGHT, VITALSIGN_CODE_WEIGHT].includes(
                  newTableRowData[index].code
                )
              ) {
                const bmis = newTableRowData.find((member) => {
                  return member.code == VITALSIGN_CODE_BMI;
                });
                // newTableRowData, isNew, measurement_id
                var bmi = getBMI(newTableRowData, false, measurement_id);
                bmis[measurement_id].formatted_string = bmi;
              }
              // setSelectedCell({});
            };

            if (
              selectedCell &&
              selectedCell.hasOwnProperty(measurement_id) &&
              props.index === selectedCell[measurement_id]
            ) {
              return (
                <Input
                  id={`Input-SavedTableRow-${index}`}
                  style={{ width: "inherit" }}
                  value={props.original[measurement_id].formatted_string}
                  autoFocus
                  onChange={(e) => {
                    if (index > -1) {
                      newTableRowData[index][measurement_id] = {
                        ...newTableRowData[index][measurement_id],
                        formatted_string: e.target.value,
                      };

                      if (
                        [VITALSIGN_CODE_BMI, VITALSIGN_CODE_HEIGHT, VITALSIGN_CODE_WEIGHT].includes(
                          newTableRowData[index].code
                        )
                      ) {
                        const bmis = newTableRowData.find((member) => {
                          return member.code == VITALSIGN_CODE_BMI;
                        });

                        // newTableRowData, isNew, measurement_id
                        var bmi = getBMI(newTableRowData, false, measurement_id);
                        bmis[measurement_id].formatted_string = bmi;
                      }

                      setTableRowData(newTableRowData);
                    }
                  }}
                  onFocus={(e: any) => {
                    // console.log("focus b", props.index , selectedCell);
                    // console.log(newTableRowData);
                    if (e.target.value === "NA") {
                      newTableRowData[index][measurement_id] = {
                        ...newTableRowData[index][measurement_id],
                        formatted_string: "",
                      };
                      setTableRowData(newTableRowData);
                    } else {
                      e.target.select();
                    }
                  }}
                  onBlur={(e: any) => {
                    setTimeout(() => {
                      preValidate();
                      setSelectedCell((pre: any) => {
                        if (pre !== selectedCell) {
                          return pre;
                        } else {
                          return {};
                        }
                      });
                    }, 100);
                  }}
                  onKeyPress={(e: any) => {
                    if (e.key === "Enter") {
                      console.log("Didn't call on development phase");
                      preValidate();
                    }
                  }}
                />
              );
            }
            return (
              <div
                className="reactTablePadding"
                style={{ color: props.original[measurement_id]?.color }}
              >
                {props.original[measurement_id]?.formatted_string}
              </div>
            );
          },
        });
      });
      if (!props.readOnly) {
        headerColumn.push({
          Header: "New Vital Sign",
          accessor: "newVitalSign",
          className: "noPadding",
          getProps: (state: any, rowInfo: any, column: any) => {
            if (rowInfo) {
              if (
                originalTableRowData[rowInfo.index] &&
                originalTableRowData[rowInfo.index].newVitalSign !== rowInfo.original.newVitalSign
              ) {
                return {
                  style: { background: "#CFFFC4" },
                };
              }
            }

            return {
              style: { background: "#FFFFFF" },
            };
          },
          Cell: (props: any) => {
            let index = tableRowData.findIndex((items: any) => items.name === props.original.name);
            let newTableRowData = [...tableRowData];
            const preValidate = () => {
              if (
                props.original.newVitalSign.length === 0 ||
                props.original.newVitalSign === "NA"
              ) {
                if (index > -1) {
                  newTableRowData[index] = {
                    ...newTableRowData[index],
                    newVitalSign: "NA",
                  };
                  setTableRowData(newTableRowData);
                }
              } else {
                var validate_result = validateData(
                  props.original.newVitalSign,
                  props.original.result_type
                );
                if (!validate_result) {
                  newTableRowData[index] = {
                    ...newTableRowData[index],
                    newVitalSign: originalTableRowData[index].newVitalSign,
                  };
                  setTableRowData(newTableRowData);
                }
              }
              if (
                [VITALSIGN_CODE_BMI, VITALSIGN_CODE_HEIGHT, VITALSIGN_CODE_WEIGHT].includes(
                  newTableRowData[index].code
                )
              ) {
                const bmis = newTableRowData.find((member) => {
                  return member.code == VITALSIGN_CODE_BMI;
                });
                //newTableRowData, dateTime, isNew
                if (bmis) {
                  var bmi = getBMI(newTableRowData, true, null);
                  bmis.newVitalSign = bmi;
                }
              }
              // setSelectedCell({});
            };
            if (
              selectedCell &&
              selectedCell.hasOwnProperty("newVitalSign") &&
              props.index === selectedCell.newVitalSign
            ) {
              return (
                <Input
                  id={`Input-TableRow-${index}`}
                  style={{ width: "inherit" }}
                  value={props.original.newVitalSign}
                  autoFocus
                  onChange={(e) => {
                    if (index > -1) {
                      newTableRowData[index] = {
                        ...newTableRowData[index],
                        newVitalSign: e.target.value,
                      };

                      if (
                        [VITALSIGN_CODE_BMI, VITALSIGN_CODE_HEIGHT, VITALSIGN_CODE_WEIGHT].includes(
                          newTableRowData[index].code
                        )
                      ) {
                        const bmis = newTableRowData.find((member) => {
                          return member.code == VITALSIGN_CODE_BMI;
                        });
                        //newTableRowData, dateTime, isNew
                        if (bmis) {
                          var bmi = getBMI(newTableRowData, true, null);
                          bmis.newVitalSign = bmi;
                        }
                      }

                      setTableRowData(newTableRowData);
                    }
                  }}
                  onFocus={(e: any) => {
                    console.log("focus a");
                    if (e.target.value === "NA") {
                      newTableRowData[index] = {
                        ...newTableRowData[index],
                        newVitalSign: "",
                      };
                      setTableRowData(newTableRowData);
                    } else {
                      e.target.select();
                    }
                  }}
                  onBlur={(e: any) => {
                    setTimeout(() => {
                      preValidate();
                      setSelectedCell((pre: any) => {
                        if (pre !== selectedCell) {
                          return pre;
                        } else {
                          return {};
                        }
                      });
                    }, 100);
                  }}
                  onKeyPress={(e: any) => {
                    if (e.key === "Enter") {
                      console.log("Didn't call on development phase");
                      preValidate();
                    }
                  }}
                  onKeyDown={(e: any) => {
                    if (e.key === "ArrowDown" || e.key === "Enter") {
                      let nextIndex = index + 1;
                      if (
                        tableRowData.length == nextIndex ||
                        tableRowData[nextIndex].code === VITALSIGN_CODE_BMI
                      ) {
                        // TODO: If BMI skip to next row, but this is out focus (need both implement NewVitalSignCell and VitalSign)
                        setSelectedCell({});
                      } else {
                        setSelectedCell({ newVitalSign: nextIndex });
                      }
                    } else if (e.key === "ArrowUp") {
                      let previousIndex = index - 1;
                      if (
                        previousIndex < 0 ||
                        tableRowData[previousIndex].code === VITALSIGN_CODE_BMI
                      ) {
                        setSelectedCell({});
                      } else {
                        setSelectedCell({ newVitalSign: previousIndex });
                      }
                    }
                  }}
                />
              );
            }
            return <div id={`Input-DefaultRow-${index}`} className="reactTablePadding">{props.original.newVitalSign}</div>;
          },
        });
      }

      setTableColumnData(headerColumn);
    };

    const createTableRow = () => {
      let rowData = getTableRowVitalSign({
        vitalSignType,
        vitalSign,
        lastHeight: Number(lastHeight),
      });

      rowData = filterVitalSignTypeByAge(rowData, props.vitalSignTypeByAge);

      setOriginalTableRowData(JSON.parse(JSON.stringify(rowData))); // clone rowData

      setTableRowData(rowData);
    };

    const createChartLabel = () => {
      let chartLabel: any[] = [];
      vitalSign.map((vitalSignDataItem) => {
        let dateTime = `${vitalSignDataItem.date} ${vitalSignDataItem.time}`;
        if (props.useUTCTime && vitalSignDataItem.date_time_utc) {
          const [dateComponent, timeComponent] = formatUTCtoBEString(
            vitalSignDataItem.date_time_utc
          );
          dateTime = `${dateComponent}\n${timeComponent}`;
        }

        chartLabel.push(dateTime);
      });
      setChartLabel(chartLabel);
    };

    const generateLeftAxisLabel = () => {
      let label = "";
      vitalSignType.map((items) => {
        if (!items.graph_shown) {
          return;
        }
        if (items.y_axis === 1) {
          label += items.code + "  ";
        }
      });
      return label;
    };

    const generateRightAxisLabel = () => {
      let label = "";
      vitalSignType.map((items) => {
        if (!items.graph_shown) {
          return "";
        }
        if (items.y_axis === 2) {
          label += items.code + "  ";
        }
      });
      return label;
    };

    const vitalSignTypeOptions = useMemo(() => {
      return vitalSignType.map((item: any, index: number) => ({
        key: index,
        text: `${item.name}(${item.unit})`,
        value: item.id,
      }));
    }, [vitalSignType]);

    return (
      <div ref={divRef}>
        <div ref={rightRef} style={{ position: "fixed", right: 0 }}></div>

        <CardLayout
          titleText="Vital Sign/ Neurological Sign"
          closeable={props.closeable}
          toggleable={props.toggleable}
          loading={isLoading}
          onClose={props.hideCallback}
          style={props.cardStyle}
          hideHeaderIcon={props.hideHeaderIcon}
          headerColor={props.headerColor}
          dimmerStyle={{ minWidth: "max-content" }}
        >
          <ModInfo
            open={showModInfo}
            titleColor={alertTitleColor}
            alertText={alertText}
            titleName={alertTitleText}
            onApprove={() => setShowModInfo(false)}
          />

          <Confirm
            open={isShowDuplicateDialog}
            onCancel={dismissDupDialog}
            onConfirm={saveVitalSign}
            size={"tiny"}
            content={messageDupDialog}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            cancelButton={intl.formatMessage({ id: "ยกเลิก" })}
            confirmButton={intl.formatMessage({ id: "ตกลง" })}
          />

          <Modal open={authmodalOpen} onClose={handleModVerifyUserCancel} size="mini" closeIcon>
            <Modal.Content>
              <Form>
                <Form.Input
                  id="CardVitalSign-Input-Username"
                  placeholder="Username"
                  name="name"
                  value={cancelUser}
                  label="Username"
                  autoComplete="off"
                  onChange={(e: any, item: any) => setCancelUser(item.value)}
                />
                <Form.Input
                  id="CardVitalSign-Input-Password"
                  placeholder="Password"
                  name="email"
                  value={cancelPassword}
                  type="password"
                  label="Password"
                  onChange={(e: any, item: any) => setCancelPassword(item.value)}
                />
                <ErrorMessage style={{ width: "100%" }} error={authErrorsMessage} />
                <Button
                  id="CardVitalSign-Button-SubmitVerify"
                  color="yellow"
                  style={{ width: "100%" }}
                  className={isLoading ? "ui button disabled loading" : "ui button"}
                  onClick={handleSubmitVerify}
                >
                  ยืนยัน ยกเลิกค่า Vital Sign
                </Button>
              </Form>
            </Modal.Content>
          </Modal>

          {!props.hideFilter && (
            <div className="ui form" ref={formRef} style={{ width: "fit-content" }}>
              <Form.Group inline>
                <Form.Checkbox
                  id="CardVitalSign-Checkbox-SpecDate"
                  label={intl.formatMessage({ id: "ค้นหาโดยระบุวันที่" })}
                  checked={chkDateSearch}
                  onChange={(e: any, { checked }: any) => setChkDateSearch(checked)}
                />
                <Form.Field>
                  <DateTextBox
                    id="CardVitalSign-DateTextBox-StartDate"
                    inputStyle={{ width: "150px" }}
                    value={startDate}
                    onChange={(date: any) => setStartDate(date)}
                  />
                </Form.Field>
                <Form.Field>ถึง</Form.Field>
                <Form.Field>
                  <DateTextBox
                    id="CardVitalSign-DateTextBox-EndDate"
                    inputStyle={{ width: "150px" }}
                    value={endDate}
                    onChange={(date: any) => setEndDate(date)}
                  />
                </Form.Field>
                <Form.Checkbox
                  id="CardVitalSign-Checkbox-SpecTime"
                  label={intl.formatMessage({ id: "ค้นหาโดยระบุเวลา" })}
                  checked={chkTimeSearch}
                  onChange={(e: any, { checked }: any) => setChkTimeSearch(checked)}
                />
                <Form.Field>
                  <TimeComboBox
                    id="CardVitalSign-TimeComboBox-StartTime"
                    style={{ width: "100px" }}
                    // ref={timeStartRef}
                    defaultValue={startTime || ""}
                    onTextChange={(startTime) => setStartTime(startTime)}
                  />
                </Form.Field>
                <Form.Field>ถึง</Form.Field>
                <Form.Field>
                  <TimeComboBox
                    id="CardVitalSign-TimeComboBox-EndTime"
                    style={{ width: "100px" }}
                    // ref={timeEndRef}
                    defaultValue={endTime || ""}
                    onTextChange={(endTime) => setEndTime(endTime)}
                  />
                </Form.Field>
                <Button
                  id="CardVitalSign-Button-Search"
                  color="blue"
                  onClick={(e) => {
                    getVitalSign(TABLE_LIMIT, 0);
                  }}
                >
                  ค้นหา
                </Button>
              </Form.Group>
            </div>
          )}
          <Grid
            columns={props.hideBarChart ? 1 : 2}
            style={{ width: `${width}px` }}
          >
            {!props.hideBarChart && (
              <Grid.Column>
                <Bar
                  plugins={[]}
                  data={{
                    labels: subChartData.labels,
                    datasets: subChartData.datasets,
                  }}
                  options={{
                    plugins: {
                      datalabels: {
                        display: false,
                      },
                    },
                    pan: {
                      // Boolean to enable panning
                      enabled: true,

                      // Panning directions. Remove the appropriate direction to disable
                      // Eg. 'y' would only allow panning in the y direction
                      mode: "xy",
                    },

                    // Container for zoom options
                    zoom: {
                      // Boolean to enable zooming
                      enabled: true,

                      // Zooming directions. Remove the appropriate direction to disable
                      // Eg. 'y' would only allow zooming in the y direction
                      mode: "xy",
                    },
                    legend: {
                      labels: {
                        filter: function (item: any, chart: any) {
                          return item.text !== "Diastolic(mmHg)";
                        },
                        generateLabels: (chart: any) => {
                          const datasets = chart.data.datasets;
                          return datasets.map((dataset: any, index: number) => {
                            const color = dataset.backgroundColor
                              ? dataset.backgroundColor?.substring(0, 7)
                              : "transparent";

                            return {
                              hidden: dataset.hidden,
                              datasetIndex: index,
                              text: dataset.label.includes("Systolic/Diastolic")
                                ? dataset.label.replace(/Systolic\/Diastolic/g, "BloodPressure")
                                : dataset.label,
                              strokeStyle: color,
                              fillStyle: color,
                            };
                          });
                        },
                      },
                    },
                    tooltips: {
                      mode: "single",
                      filter: function (tooltipItem: any, data: any) {
                        var label = data.datasets[tooltipItem.datasetIndex].label || "";
                        return label !== "Diastolic(mmHg)";
                      },
                      callbacks: {
                        label: function (tooltipItem: any, data: any) {
                          var label = data.datasets[tooltipItem.datasetIndex].label || "";
                          var diastolicData = data.datasets.find(
                            (items: any) => items.label === "Diastolic(mmHg)"
                          );
                          if (diastolicData) {
                            var diastolic = diastolicData.data[tooltipItem.index]
                              ? diastolicData.data[tooltipItem.index].y
                              : "";
                          }

                          if (label === "Diastolic(mmHg)") {
                            return;
                          }
                          if (label) {
                            label += ": ";
                          }
                          if (label.includes("Systolic")) {
                            label +=
                              parseInt(tooltipItem.yLabel) + parseInt(diastolic) + "/" + diastolic;
                          } else {
                            label += Math.round(tooltipItem.yLabel * 100) / 100;
                          }

                          if (label.includes("Systolic/Diastolic")) {
                            label = label.replace(/Systolic\/Diastolic/g, "BloodPressure");
                          }

                          return label;
                        },
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          categoryPercentage: 0.1,
                          maxBarThickness: 5,
                        },
                      ],
                      yAxes: [
                        {
                          id: 1,
                          type: "linear",
                          position: "left",
                          scaleLabel: {
                            display: true,
                            // labelString: generateLeftAxisLabel(),
                          },
                        },
                        {
                          id: 2,
                          type: "linear",
                          position: "right",
                          scaleLabel: {
                            display: true,
                            labelString: generateRightAxisLabel(),
                          },
                        },
                      ],
                    },
                  }}
                />

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <Icon
                    name={`caret square left`}
                    size="large"
                    disabled={!subChartData.isPrev}
                    style={{ cursor: subChartData.isPrev ? "pointer" : "" }}
                    onClick={handleStepChange("left")}
                  ></Icon>
                  <Icon
                    name={`caret square right`}
                    size="large"
                    disabled={!subChartData.isNext}
                    style={{ cursor: subChartData.isNext ? "pointer" : "" }}
                    onClick={handleStepChange("right")}
                  ></Icon>
                </div>
              </Grid.Column>
            )}
            <Grid.Column
              style={props.hideBarChart ? { width: "100%" } : { width: "53%", marginLeft: "-3%" }}
            >
              <ReactTableFixedColumns
                ref={myRef}
                style={{ height: "390px" }}
                showPagination={false}
                data={tableRowData}
                columns={tableColumnData}
                className={"fix-height"}
                pageSize={tableRowData ? (tableRowData.length < 9 ? 9 : tableRowData.length) : 9}
                getTrGroupProps={(state: any, rowInfo: any) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      style: { height: "auto" },
                    };
                  } else {
                    return {};
                  }
                }}
                getTheadThProps={() => {
                  return {
                    style: {
                      backgroundColor: "var(--primary-theme-color)",
                    },
                  };
                }}
                getTrProps={(state: any, rowInfo: any, column: any) => {
                  if (rowInfo && rowInfo.row) {
                    let background =
                      rowInfo.index === (rowSelected ? rowSelected.index : null)
                        ? "blueSelectedRow"
                        : "";
                    return {
                      onClick: (e: any) => {
                        if (rowInfo.id !== rowSelected.id) {
                          setRowSelected(rowInfo);
                        }
                      },
                      className: background,
                    };
                  } else {
                    return {};
                  }
                }}
                getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                  return {
                    onClick: (e: any, handleOriginal: any) => {
                      if (props.readOnly) {
                        return;
                      }
                      if (!rowInfo || !column) {
                        return;
                      }
                      if (rowInfo.original.code === VITALSIGN_CODE_BMI) {
                        //BMI type
                        return;
                      }

                      const elementId = `CardVitalSign-Table-Row-${rowInfo.index}`;

                      if (
                        column.id === "newVitalSign" &&
                        selectedCell.newVitalSign !== rowInfo.index
                      ) {
                        setSelectedCell({ [column.id]: rowInfo.index });
                      } else if (selectedCell[column.id] !== rowInfo.index) {
                        setSelectedCell({ [column.id]: rowInfo.index });
                      }
                    },
                    onKeyDown: (e: any) => {
                      if (e.key == "Enter" || e.key == "ArrowDown") {
                        let nextIndex = rowInfo.index + 1;
                        if (
                          tableRowData.length == nextIndex ||
                          tableRowData[nextIndex].code === VITALSIGN_CODE_BMI
                        ) {
                          setSelectedCell({});
                        } else {
                          setSelectedCell({ [column.id]: nextIndex });
                        }
                      } else if (e.key == "ArrowUp") {
                        let previousIndex = rowInfo.index - 1;
                        if (
                          previousIndex < 0 ||
                          tableRowData[previousIndex].code === VITALSIGN_CODE_BMI
                        ) {
                          setSelectedCell({});
                        } else {
                          setSelectedCell({ [column.id]: previousIndex });
                        }
                      }
                    },
                  };
                }}
              />
            </Grid.Column>
          </Grid>
          <Grid columns={2}>
            {props.showBottomLeftForm ? (
              <>
                <Grid.Column className="noPadding">
                  <Form className="noPadding">
                    <Form.Group inline style={{ justifyContent: "center" }}>
                      <Form.Field width={1}></Form.Field>

                      <Form.Field width={5}>
                        <label>{intl.formatMessage({ id: "ชนิด Vital Sign" })}</label>
                        {/* <Input ref={(instance) => {
                    if (instance) {
                      inputVitalSignRef.current = instance.inputRef.current
                    }
                  }} /> */}
                        <ComboBox
                          options={vitalSignTypeOptions}
                          search={true}
                          selection={true}
                          clearable={true}
                          placeholder={intl.formatMessage({ id: "เลือก" })}
                          fluid={true}
                          value={dropdownVitalSignType}
                          onChange={(e: any, { value }: any) => {
                            setDropdownVitalSignType(value);
                          }}
                        />
                      </Form.Field>
                      <Form.Field width={4}>
                        <label>{intl.formatMessage({ id: "ค่าที่วัดได้" })}</label>
                        <Input
                          ref={(instance: any) => {
                            if (instance) {
                              inputMeasuredValueRef.current = instance.inputRef.current;
                            }
                          }}
                        />
                      </Form.Field>
                      <Form.Field width={3}>
                        <Radio
                          id="CardVitalSign-Radio-CurrentDateTime"
                          label={intl.formatMessage({ id: "วันที่เวลาปัจุบัน" })}
                          name="radioGroup"
                          value="current"
                          checked={radioCheckedDate === "current"}
                          onChange={(e: any, { value }: any) => {
                            setRadioCheckedDate(value);
                          }}
                        />
                      </Form.Field>
                      <Form.Field width={3}>
                        <Radio
                          id="CardVitalSign-Radio-LastDateTime"
                          label={intl.formatMessage({ id: "วันเวลาการวัดล่าสุด" })}
                          name="radioGroup"
                          value="last"
                          checked={radioCheckedDate === "last"}
                          onChange={(e: any, { value }: any) => {
                            setRadioCheckedDate(value);
                          }}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Form>
                </Grid.Column>
              </>
            ) : (
              !props.hideFooter && (
                <Grid.Column className="noPadding">
                  {/* <Form className="noPadding"> */}
                  {/* <Form.Group inline>
                  <Button color="blue" onClick={handleNextVitalSign}>
                    ก่อนหน้า
                  </Button>
                </Form.Group> */}
                  {/* </Form> */}
                </Grid.Column>
              )
            )}

            {!props.readOnly && !props.hideFooter && (
              <Grid.Column className="noPadding" style={{ width: "53%", marginLeft: "-3%" }}>
                <Form className="noPadding">
                  <Form.Group inline>
                    <Form.Field>
                      {props.useCheckboxDateManual ? (
                        <Checkbox
                          id="CardVitalSign-Checkbox-DateManual"
                          label="วัน/เวลา ที่บันทึก(ระบุเอง)"
                          name="radioGroup"
                          value="manual"
                          checked={radioCheckedDate === "manual"}
                          style={{ width: "115px" }}
                          onChange={(e, v) => {
                            if (v.value === radioCheckedDate && !props.showBottomLeftForm) {
                              setRadioCheckedDate("current");
                            } else {
                              setRadioCheckedDate(v.value);
                            }
                          }}
                        />
                      ) : (
                        <Radio
                          id="CardVitalSign-Radio-DateManual"
                          label="วัน/เวลา ที่บันทึก(ระบุเอง)"
                          name="radioGroup"
                          value="manual"
                          checked={radioCheckedDate === "manual"}
                          style={{ width: "115px" }}
                          onChange={(e: any, v: any) => {
                            if (v.value === radioCheckedDate && !props.showBottomLeftForm) {
                              setRadioCheckedDate("current");
                            } else {
                              setRadioCheckedDate(v.value);
                            }
                          }}
                        />
                      )}

                      {/* <Form.Checkbox label="วัน/เวลา ที่บันทึก(ระบุเอง)" style={{ fontWeight: "bold" }} onChange={(e, { checked }) => setChkSave(checked)} /> */}
                    </Form.Field>
                    <Form.Field style={{ minWidth: "max-content" }}>วันที่</Form.Field>
                    <Form.Field>
                      <DateTextBox
                        id="CardVitalSign-DateTextBox-SaveDate"
                        value={saveDate}
                        disabled={radioCheckedDate !== "manual"}
                        maxDate={formatDate(new Date())}
                        onChange={(date: any) => setSaveDate(date)}
                        style={{ width: "135px" }}
                        inputStyle={{ width: "135px" }}
                      />
                    </Form.Field>
                    <Form.Field>เวลา</Form.Field>
                    <Form.Field width={3}>
                      <TimeTextBox
                        id="CardVitalSign-TimeTextBox-SaveTime"
                        style={{ width: "100%", minWidth: "auto" }}
                        disabled={radioCheckedDate !== "manual"}
                        search={true}
                        value={saveTime}
                        onTimeChange={(e: any, value: any) => setSaveTime(value)}
                      />
                    </Form.Field>
                    <div style={{ flex: 1 }}></div>
                    <ButtonLoadCheck
                      // function
                      setProp={props.setProp}
                      onClick={validateDuplicationSave}
                      // data
                      paramKey={"CardVitalSign_SAVE"}
                      buttonLoadCheck={props.buttonLoadCheck?.["CardVitalSign_SAVE"]}
                      // config
                      id="CardVitalSign-ButtonLoadCheck-Save"
                      style={{ marginRight: "1rem" }}
                      color="green"
                      name="SAVE"
                      title={intl.formatMessage({ id: "บันทึก" })}
                    />
                  </Form.Group>
                </Form>
              </Grid.Column>
            )}
          </Grid>
        </CardLayout>
      </div>
    );
  }
);

export const getTableRowVitalSign = ({
  vitalSignType = [],
  vitalSign = [],
  lastHeight = -1,
}: {
  vitalSignType: any[];
  vitalSign: any[];
  lastHeight: number;
}) => {
  const rowData: any[] = [];

  vitalSignType.sort((a, b) => (a.display_seq > b.display_seq ? 1 : -1));

  for (const item of vitalSignType) {
    // #console.log("item ", item);
    const info: any = { newVitalSign: "NA" };

    vitalSign.map((vitalSignDataItem) => {
      const index = vitalSignDataItem.vital_signs.findIndex(
        (items: any) => items.name === item.name
      );

      if (index > -1) {
        const item_obj: any = {
          id: vitalSignDataItem.vital_signs[index].id,
          formatted_string: vitalSignDataItem.vital_signs[index].formatted_string,
          measurement_id: vitalSignDataItem.id,
          color: "#000000",
        };

        if (vitalSignDataItem.vital_signs[index].level === "สูง") {
          item_obj.color = "#ff0000";
        } else if (vitalSignDataItem.vital_signs[index].level === "ต่ำ") {
          item_obj.color = "#ffd300";
        }

        info[vitalSignDataItem.id] = item_obj;

        if (
          [VITALSIGN_CODE_BMI, VITALSIGN_CODE_HEIGHT, VITALSIGN_CODE_WEIGHT].includes(
            vitalSignDataItem.vital_signs[index].code
          )
        ) {
          info.newVitalSign =
            vitalSignDataItem.vital_signs[index].code === VITALSIGN_CODE_HEIGHT
              ? item_obj.formatted_string || lastHeight
              : item_obj.formatted_string;
        }
        return;
      }

      const item_obj: any = {
        formatted_string: "NA",
        measurement_id: vitalSignDataItem.id,
      };

      info[vitalSignDataItem.id] = item_obj;
    });

    rowData.push({
      name: `${item.name}(${item.unit})`,
      ...info,
      vitalsign_type: item.id,
      result_type: item.result_type,
      code: item.code,
    });
  }

  return rowData;
};

export const filterVitalSignTypeByAge = (list: any[], age?: number) => {
  if (typeof age !== "number") {
    return list;
  }

  return list.filter((item) => {
    const ageString = item.name.match(/((\d+-\d+)|(\d+\+))(?= ปี)/g)?.[0];

    if (ageString?.includes("+")) {
      const minAge = Number(ageString.match(/\d+/g)?.[0]);
      return age > minAge;
    } else if (ageString?.includes("-")) {
      const [min, max] = ageString.split("-");
      return age >= Number(min) && age <= Number(max);
    } else {
      return true;
    }
  });
};

CardVitalSign.displayName = "CardVitalSign";
export default React.memo(CardVitalSign);
