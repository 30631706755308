import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Icon, Button } from "semantic-ui-react";

// UX
import CardVerbalOrderUX from "./CardVerbalOrderUX";
import CardLabRequest from "react-lib/apps/HISV3/LAB/CardLabRequest";
import CardVerbalOrderHistory from "./CardVerbalOrderHistory";
import CardAdmitOrder from "react-lib/apps/ADM/CardAdmitOrder";
import CardTreatmentOrder from "react-lib/apps/HISV3/DPO/CardTreatmentOrder";
import CardDoctorNoteOrder from "react-lib/apps/HISV3/DPO/CardDoctorNoteOrder";
import CardImagingOrder from "react-lib/apps/HISV3/IME/CardImagingOrder";
import CardDrugOrder from "react-lib/apps/HISV3/TPD/CardDrugOrder";
import ModLockExpense from "../BIL/ModLockExpense";

// Common
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import ModMedReconcileAlert from "react-lib/apps/common/ModMedReconcileAlert";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import SearchBoxDropdown, { SelectedItemHandler } from "react-lib/appcon/common/SearchBoxDropdown";
import EmployeeToken from "react-lib/apps/common/EmployeeToken";
import PasswordRecheck from "react-lib/apps/common/PasswordRecheck";

import { VERBAL_ORDER_TYPES } from "./PTMInterface";
import { useIntl } from "react-intl";

// Interface
import { CARD_DUPLICATE_REASON, getOrderTypes } from "../TPD/TPDInterface";

import CONFIG from "config/config";

// Const
const DOCTOR_SEARCH_ID = "Doctor_VO";

const CardVerbalOrder = (props: any) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const [containerName, setContainerName] = useState<string>("drug");
  const [selectedDoctor, setSelectedDoctor] = useState<Record<string, any> | null>(null);
  const [password, setPassword] = useState<string>("");
  const [orderType, setOrderType] = useState("");
  const [editOrder, setEditOrder] = useState<any>(null);
  const [selectedImagingId, setSelectedImagingId] = useState<number | null>(null);
  const [isDrugRequested, setIsDrugRequested] = useState(false);

  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  const [verbalOrderSave, setVerbalOrderSave] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);

  // * Clear verbalOrderHistoryList แท็บ ประวัติ รคส. เมื่อ เข้าหน้า รคส. แพทย์ ครั้งแรก
  useEffect(() => {
    props.setProp("verbalOrderHistoryList", []);
    props.setProp("orderSpecificType", "");

    return () => {
      props.setProp("orderSpecificType", "");
    };
  }, []);

  useEffect(() => {
    setSelectedDoctor({
      id: props.selectedEncounter?.doctor,
      name_code: props.selectedEncounter?.doctor_name,
    });
  }, [props.selectedEncounter]);

  useEffect(() => {
    console.log("duplicateLabDetected ", props.duplicateLabDetected);
    if (props.duplicateLabDetected === true) {
      setOpenConfirm(true);
    }
  }, [props.duplicateLabDetected]);

  useEffect(() => {
    if (
      props.errorMessage?.CardVerbalOrder !== null &&
      props.errorMessage?.CardVerbalOrder !== undefined
    ) {
      setLoading(false);
      console.log("setLoading(false)");
      setErrorMessage(props.errorMessage?.CardVerbalOrder);

      // setTimeout(() => {
      //   props.onEvent({
      //     message: "SaveVerbalOrder",
      //     params: { action: "clearMessage" },
      //   });
      // }, 5000);
    }
    if (
      props.successMessage?.CardVerbalOrder !== null &&
      props.successMessage?.CardVerbalOrder !== undefined
    ) {
      setLoading(false);
      console.log("setLoading(false)");
      setCompleted(true);

      handleClearValue();

      setTimeout(() => {
        setCompleted(false);
        // #props.setProp("successMessage.CardVerbalOrder", "");
        // props.onEvent({
        //   message: "SaveVerbalOrder",
        //   params: { action: "clearMessage" },
        // });
      }, 3000);
      // #toast.success("บันทึกข้อมูลสำเร็จ");
    }
  }, [props.successMessage?.CardVerbalOrder, props.errorMessage?.CardVerbalOrder]);

  useEffect(() => {
    if (props.buttonLoadCheck?.CardDrugOrder_CONFIRM === "SUCCESS") {
      setLoading(false);
      setCompleted(true);

      handleClearValue();

      props.setProp("buttonLoadCheck.CardDrugOrder_CONFIRM", null);

      setTimeout(() => {
        setCompleted(false);
      }, 3000);
    }
  }, [props.buttonLoadCheck?.CardDrugOrder_CONFIRM]);

  useEffect(() => {
    if (containerName === "drug") {
      if (props.drugOrder?.id && props.drugOrder?.isSelected) {
        setOrderType(props.drugOrder.order_type);

        const doctorItem = props.verbalDrugOrderList?.find(
          (item: any) =>
            item.ref_order === props.drugOrder.id && item.co_signed_id === props.drugOrder.order_by
        );

        if (doctorItem) {
          const doctor = { id: doctorItem.co_signed_id, name_code: doctorItem.co_signed };

          setSelectedDoctor(doctor);
        }
      } else {
        setOrderType("");
        // setSelectedDoctor(null);
        setSelectedDoctor({
          id: props.selectedEncounter?.doctor,
          name_code: props.selectedEncounter?.doctor_name,
        });
      }
    }
  }, [containerName, props.drugOrder?.id, props.verbalDrugOrderList, props.drugOrder?.isSelected]);

  // callback
  const handleSelectedItem: SelectedItemHandler = useCallback(
    async (value, key, data) => {
      setSelectedDoctor(data || null);
    },
    [props.searchedItemListWithKey]
  );

  // Memo
  const orderByName = useMemo(() => {
    const nameCode: string = selectedDoctor?.name_code || "";

    return nameCode.replace(/ ?\(.*\)/, "") || "-";
  }, [selectedDoctor]);

  const hideAdmitOrder = useMemo(
    () => props.selectedEncounter?.type === "IPD",
    [props.selectedEncounter]
  );

  const buttonLists = useMemo(
    () =>
      [
        { code: "VerbalOrderHistory", label: "ประวัติ รคส.", name: "history" },
        {
          code: "VerbalOrderAdmit",
          disabled: hideAdmitOrder,
          label: "Admit Order",
          name: "admit",
        },
        { code: "VerbalOrderDrug", label: "ยา", name: "drug" },
        { code: "VerbalOrderLab", label: "Central Lab", name: "lab" },
        { code: "VerbalOrderTreatment", label: "Treatment Order", name: "treatment" },
        { code: "VerbalOrderImaging", label: "Imaging", name: "imaging" },
        { code: "VerbalOrderDrnote", label: "คำสั่งอื่นๆ", name: "drnote" },
      ].filter((items: any) => !CONFIG.V3_HIDDEN_TAB_LIST.includes(items.code)),
    [hideAdmitOrder]
  );

  const hiddenPerformed = useMemo(() => {
    if (containerName === "drug") {
      return !isDrugRequested;
    }

    return containerName === "history";
  }, [containerName, isDrugRequested, props.drugOrder?.id, props.drugOrder?.isSelected]);

  // Handler
  const handleClearValue = async () => {
    setOrderType("");
    setPassword("");

    props.setProp(`searchedItemListWithKey.${DOCTOR_SEARCH_ID}`, []);

    // Drug
    props.setProp("drugVerbalFreeTxt", {
      id: null,
      ref_order: null,
      drugType: "",
      note: "",
    });

    setEditOrder(null);

    if (props.selectedEncounter?.doctor && props.selectedEncounter?.doctor_name) {
      setSelectedDoctor({
        id: props.selectedEncounter?.doctor,
        name_code: props.selectedEncounter?.doctor_name,
      });
    } else {
      setSelectedDoctor(null);
    }

    let tab = "";

    await setContainerName((containerName) => {
      tab = containerName;

      return "";
    });

    setContainerName(tab);
  };

  const handleSaveDrug = async (data: any) => {
    if (props.django?.user?.role_types?.includes("PHARMACIST")) {
      if (
        (!props.openModPharmacistNote?.open && !props.openModPharmacistNote?.confirmed) ||
        props.openModPharmacistNote?.note === ""
      ) {
        props.setProp("openModPharmacistNote", { open: true, note: "" });
        return;
      }
    }

    setLoading(true);

    let saveOrder: any = null;

    if (props.django?.user?.role_types?.includes("PHARMACIST") && orderType === "DIRECT") {
      saveOrder = await props.onEvent({
        message: "ConfirmDrugOrder",
        params: {
          ...props.drugOrder,
          card: CARD_DUPLICATE_REASON,
          cardKey: "CardDrugOrder",
          data_changed: true,
          action: "CONFIRM",
          emr: props.selectedEmr?.id,
          progression_cycle: props.selectedProgressCycle?.id,
          order_div: props.drugOrder?.order_div?.toString() || "",
          is_out_perform_div: false,
          pharmacist_note: props.openModPharmacistNote?.note || "",
          orderType: props.orderSpecificType,
          note: props.drugVerbalFreeTxt?.note || props.drugOrder?.note || "",
          duplicated_note: data?.duplicated_note || props.drugOrder?.duplicated_note,
          confirm_duplicated_drugs:
            data?.confirm_duplicated_drugs || props.drugOrder?.confirm_duplicated_drugs,
        },
      });
    } else {
      
      let drugOrderData = { ...props.drugOrder };
      drugOrderData?.items?.map((item: any) => {
        if (item.is_solvent) {
          if (!item.frequency) {
            delete item.frequency;
          }
          if (!item.route) {
            delete item.route;
          }
        }
        return item;
      });

      saveOrder = await props.onEvent({
        message: "SaveVerbalOrder",
        params: {
          action: "REQUEST",
          id: props.drugVerbalFreeTxt?.id || null,
          order_specific_type: "DRUG",
          encounterId: props.selectedEncounter?.id,
          progression_cycle: props.selectedProgressCycle?.id,
          note: props.drugVerbalFreeTxt?.note || "",
          order_by: selectedDoctor?.id || null,
          repassword: password || "",
          order_type: orderType,
          order: {
            ...drugOrderData,
            action: props.drugVerbalFreeTxt?.ref_order ? "EDIT" : "REQUEST",
            id: props.drugOrder?.id || null,
            emr: props.selectedEmr?.id,
            progression_cycle: props.selectedProgressCycle?.id,
            order_div: props.drugOrder?.order_div?.toString() || "",
            is_out_perform_div: false,
            pharmacist_note: props.openModPharmacistNote?.note || "",
            note: data?.note || props.drugOrder?.note,
            duplicated_note: data?.duplicated_note || props.drugOrder?.duplicated_note,
            confirm_duplicated_drugs:
              data?.confirm_duplicated_drugs || props.drugOrder?.confirm_duplicated_drugs,
          },
        },
      });
    }

    if (!saveOrder?.isErrorHandled) {
      props.setProp("openModPharmacistNote", { open: false, note: "" });
    }

    setLoading(false);
  };

  const handleSaveLab = (allowDupLab: boolean) => {
    setLoading(true);

    props.onEvent({
      message: "SaveVerbalOrder",
      params: {
        action: "REQUEST",
        id: editOrder?.id,
        encounterId: props.selectedEncounter?.id,
        note: props.OrderCentralLabSequence?.labOrder?.note || "",
        order_by: selectedDoctor?.id || null,
        repassword: password || "",
        order_specific_type: "LAB_ORDER",
        order_type: orderType,
        order: {
          ...editOrder,
          id: editOrder?.ref_order,
          action: editOrder ? "EDIT" : "ORDER",
          progression_cycle: props.selectedProgressCycle?.id,
          emr: props.selectedEmr?.id,
          encounter: props.selectedEncounter?.id,
          order_items: props.OrderCentralLabSequence?.labOrder?.order_items,
          note: props.OrderCentralLabSequence.labOrder?.note,
          allow_duplicate_flag: allowDupLab || false,
          order_status: props.OrderCentralLabSequence?.labOrder?.order_status
            ? props.OrderCentralLabSequence.labOrder.order_status
            : props.selectedEncounter?.type === "IPD"
            ? 2
            : 2,
        },
      },
    });
  };

  const handleSaveTreatment = () => {
    setLoading(true);
    console.log("setLoading(true)");

    const orderItems = editOrder?.ref_order
      ? props?.OrderTreatmentSequence?.treatmentList
      : props.OrderTreatmentSequence?.orderItems;
    let orderItemsNoId = [];

    if (orderItems) {
      if (editOrder?.ref_order) {
        orderItemsNoId = orderItems.map(function (item: any) {
          delete item.order;
          delete item.pk;
          return item;
        });
      } else {
        orderItemsNoId = orderItems.map(function (item: any) {
          delete item.id;
          return item;
        });
      }
    }

    // copy from OrderTreatment.ts > SearchAndAdd > action save
    const principal = props.OrderTreatmentSequence?.diagnosisListPrincipal || [];
    const secondary = props.OrderTreatmentSequence?.diagnosisListSecondary || [];
    const coreProcedure = [...principal, ...secondary];
    const procedure = coreProcedure?.map((item: any) => ({
      active: item.active,
      created: item.created,
      edit_user: item.edited,
      emr: item.emr,
      icd9cm: item.icd9cm,
      icd9cm_code: item.icd9cm_code,
      icd9cm_med_term: item.medterm_id || null,
      icd9cm_term: item.icd9cm_term,
      id: item.id,
      medical_description: item.medterm_id ? item.icd9cm_med_term : item.medical_description,
      order: item.order,
      type: item.type,
    }));
    const core_procedure = editOrder ? procedure : coreProcedure;
    // ----- end copy

    let data = {
      ...editOrder,
      id: editOrder?.ref_order,
      action: editOrder ? "EDIT" : "ORDER",
      claim_payload: null,
      core_procedure: core_procedure,
      emr: props.selectedEmr?.id,
      encounter: props.selectedEncounter?.id,
      equipment_detail: props.treatmentOrderList?.equipmentDetail,
      is_advance: false,
      is_operation_division: false,
      is_out_perform_div: false,
      note: "",
      order_detail: props.treatmentOrderList?.orderDetail,
      order_items: orderItemsNoId,
      order_status: 2,
      order_summary_detail: "",
      order_time: null,
      out_perform_div: "",
      progression_cycle: "",
      show_alert: true,
      to_be_performer: props.treatmentOrderList?.toBePerformer,
      _items: props?.OrderTreatmentSequence?.treatmentList,
      _principalDiag: [],
      _secondaryDiag: [],
    };

    props.onEvent({
      message: "SaveVerbalOrder",
      params: {
        action: "REQUEST",
        id: editOrder?.id,
        encounterId: props.selectedEncounter?.id,
        note: "",
        order_by: selectedDoctor?.id || null,
        repassword: password || "",
        order_specific_type: "TREATMENT_ORDER",
        order_type: orderType,
        order: data,
      },
    });
  };

  const handleSaveAdmitOrder = (data: {}) => {
    props.onEvent({
      message: "SaveVerbalOrder",
      params: {
        action: "REQUEST",
        id: editOrder?.id,
        encounterId: props.selectedEncounter?.id,
        note: "",
        order_by: selectedDoctor?.id || null,
        repassword: password || "",
        order_specific_type: "ADMIT_ORDER",
        order_type: orderType,
        order: {
          ...editOrder,
          id: editOrder?.ref_order,
          action: editOrder ? "EDIT" : "ORDER",
          ...data,
        },
      },
    });

    setVerbalOrderSave(false);
  };

  const handleSaveDupLab = () => {
    props.setProp("duplicateLabDetected", false);
    handleSaveLab(true);
    handleCloseModConfirm();
  };

  const handleSaveImagingOrder = () => {
    setLoading(true);

    const imaging = props.ImagingOrderSequence;
    const list = imaging?.imagingItemList || [];
    const filterList = list.filter((item: any) => !!item.active);
    props.onEvent({
      message: "SaveVerbalOrder",
      params: {
        action: "REQUEST",
        id: editOrder?.id,
        encounterId: props.selectedEncounter?.id,
        note: props.DoctorNoteOrderSequence?.note || "",
        order_by: selectedDoctor?.id || null,
        repassword: password || "",
        order_specific_type: "IMAGING_ORDER",
        order_type: orderType,
        order: {
          ...props.ImagingOrderSequence?.imagingItemDetail,
          order_items: filterList,
          id: editOrder?.ref_order,
          action: editOrder ? "EDIT" : "ORDER",
          encounter: props.selectedEncounter?.id,
          emr: props.selectedEmr?.id,
          order_div: props.ImagingOrderSequence?.imagingItemDetail?.order_div.toString(),
          is_out_perform_div: false,
          order_status: 2,
        },
      },
    });
  };

  const handleSaveDoctorNote = () => {
    setLoading(true);

    props.onEvent({
      message: "SaveVerbalOrder",
      params: {
        action: "REQUEST",
        id: editOrder?.id,
        encounterId: props.selectedEncounter?.id,
        note: props.DoctorNoteOrderSequence?.note || "-",
        order_by: selectedDoctor?.id || null,
        repassword: password || "",
        order_specific_type: "DOCTOR_NOTE_ORDER",
        order_type: orderType,
        order: {
          ...props.DoctorNoteOrderSequence,
          id: editOrder?.ref_order,
          action: editOrder ? "EDIT" : "REQUEST",
          emr: props.selectedEmr?.id,
          order_div: props.selectedDivision?.id?.toString(),
          is_out_perform_div: false,
        },
      },
    });
  };

  const handleCloseModConfirm = () => {
    props.setProp("duplicateLabDetected", false);

    setOpenConfirm(false);
    setLoading(false);
    console.log("setLoading(false)");
  };

  const handleEditVerbalOrder = (item: any) => {
    // do something here for edit order
    const tab = VERBAL_ORDER_TYPES[item.order_specific_type]?.tab;

    if (tab) {
      const doctor = { id: item.co_signed_id, name_code: item.co_signed };
      // * Set doctor, order type

      props.setProp("searchedItemListWithKey.Doctor_VO", [doctor]);

      setOrderType(item.order_type);
      setSelectedDoctor(doctor);

      if (tab === "drug") {
        // props.setProp("drugVerbalFreeTxt.drugType", item.order_specific_type);
        // props.setProp("drugVerbalFreeTxt.note", item.note);
        // setOrderDiv(division);
      } else if (tab === "treatment") {
        props.setProp("treatmentOrderEditId", item.ref_order);
      } else if (tab === "imaging") {
        setSelectedImagingId(item.ref_order);
      } else if (tab === "drnote") {
        props.setProp("doctorNoteOrderEditId", item.ref_order);
      }
      setContainerName(tab);
      setEditOrder(item);
    }
  };

  const handleSaveVerbalOrder = async (data: any) => {
    const isLocked = await props.onEvent({ message: "CheckEncounterLocker" });

    if (isLocked) {
      return;
    }

    if (containerName === "lab") {
      handleSaveLab(false);
    } else if (containerName === "treatment") {
      handleSaveTreatment();
    } else if (containerName === "admit") {
      setLoading(true);
      setVerbalOrderSave(true);
    } else if (containerName === "imaging") {
      handleSaveImagingOrder();
    } else if (containerName === "drnote") {
      handleSaveDoctorNote();
    } else {
      handleSaveDrug(data);
    }
  };

  const handleChangeTab = (tab: any) => {
    setContainerName(tab);
    setEditOrder(null);
    setOrderType("");

    // Clear ยา
    props.setProp("drugVerbalFreeTxt", {
      id: null,
      ref_order: null,
      drugType: "",
      note: "",
    });
    props.setProp("orderSpecificType", "");

    // Set default doctor
    if (props.selectedEncounter?.doctor && props.selectedEncounter?.doctor_name) {
      setSelectedDoctor({
        id: props.selectedEncounter?.doctor,
        name_code: props.selectedEncounter?.doctor_name,
      });
    } else {
      setSelectedDoctor(null);
    }

    // Clear password
    setPassword("");
    // props.onEvent({
    //   message: "CheckPasswordVerbalOrder",
    //   params: {
    //     action: "Clear",
    //   },
    // });
  };

  return (
    <div id="CardVerbalOrder" style={{ padding: "0 10px", paddingBottom: "2rem" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          setErrorMessage(null);
          props.onEvent({
            message: "SaveVerbalOrder",
            params: { action: "clearMessage" },
          });
        }}
        error={errorMessage}
        success={null}
        languageUX={props.languageUX}
      />

      <CardVerbalOrderUX
        setProp={props.setProp}
        onEvent={props.onEvent}
        // open tab
        onlyDrug={props.onlyDrug}
        containerName={containerName}
        method={orderType}
        hideMethod={containerName === "drug" && orderType === "DIRECT"}
        saveloading={loading}
        disabledSave={loading}
        hiddenPerformed={hiddenPerformed}
        // options
        methodOptions={[
          { key: "VERBAL", value: "VERBAL", text: "ทางวาจา" },
          { key: "TELEPHONE", value: "TELEPHONE", text: "ทางโทรศัพท์" },
        ]}
        // callback
        onChangeTab={handleChangeTab}
        methodOnChange={(_e: any, { value }: any) => {
          setOrderType(value);
        }}
        saveVerbalOrder={handleSaveVerbalOrder}
        // Element
        save={completed ? <Icon className="check"></Icon> : "SAVE"}
        doctorSearchBox={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type={"Doctor"}
            id="VO"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            upward={true}
            inline
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={selectedDoctor?.id || null}
            setSelectedItem={handleSelectedItem}
            defaultText={selectedDoctor?.name_code}
          />
        }
        rePasswordKey={
          <div>
            ยืนยันรหัสผู้รับคำสั่ง<span style={{ color: "red" }}>*</span>
          </div>
        }
        rePassword={
          <PasswordRecheck
            id="CardVerbalOrder-input-3"
            password={password}
            fluid
            setPassword={setPassword}
          />
        }
        buttonLists={buttonLists.map((item) => (
          <Button
            key={item.code}
            color={containerName === item.name ? "blue" : undefined}
            disabled={item.disabled}
            onClick={() => handleChangeTab(item.name)}
            style={{ marginRight: "15px" }}
          >
            {item.label}
          </Button>
        ))}
        // <EmployeeToken
        //   placeholder={"จำเป็นต้องระบุรหัสผ่าน"}
        //   onEnterToken={(code) => {
        //     props.onEvent({
        //       message: "UserTokenize",
        //       params: {
        //         action: "CheckPasswordKeying",
        //         code: code,
        //       },
        //     });

        //     // props.onEvent({
        //     //   message: "HandleGetDeliverDrugTokenization",
        //     //   params: { code },
        //     // });
        //   }}
        //   onClearToken={() => {
        //     props.setProp("userTokenize", {
        //       ...props.userTokenize,
        //       token: "",
        //       employeeName: "",
        //       loading: false,
        //       error: null,
        //     });
        //   }}
        //   error={!!props.userTokenize?.error}
        //   loading={props.userTokenize?.loading}
        //   employeeName={props.userTokenize?.employeeName}
        // />

        container={
          <div style={{ width: "100%" }}>
            {containerName === "lab" ? (
              <div style={{ width: "100%", marginTop: "-10px" }}>
                <CardLabRequest
                  setProp={props.setProp}
                  onEvent={props.onEvent}
                  // seq
                  runSequence={props.runSequence}
                  OrderCentralLabSequence={props.OrderCentralLabSequence}
                  // data
                  doctorLabOrderList={props.doctorLabOrderList}
                  editId={editOrder?.ref_order}
                  // CommonInterface
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  selectedEncounter={props.selectedEncounter}
                  selectedEmr={props.selectedEmr}
                  selectedProgressCycle={props.selectedProgressCycle}
                  buttonLoadCheck={props.buttonLoadCheck}
                  // style
                  saveLabOrderStyle={{ display: "none" }}
                  doctorLabOrderTableStyle={{ display: "none" }}
                  // config
                  isRestart={true}
                  hideActionButton={true}
                  hidePreOrder={true}
                  languageUX={props.languageUX}
                />
              </div>
            ) : containerName === "drug" ? (
              <div style={{}}>
                <CardDrugOrder
                  disabledVerbalMedOrder={false}
                  disabledConfirmedOrderBtn={true}
                  hideCardDrugVerbalOrder={false}
                  isVerbalOrder={true}
                  onSaveVerbalOrder={handleSaveVerbalOrder}
                  orderTypes={getOrderTypes(props.selectedEncounter?.type, false)}
                  setProp={props.setProp}
                  onEvent={props.onEvent}
                  runSequence={props.runSequence}
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  buttonLoadCheck={props.buttonLoadCheck}
                  selectedEncounter={props.selectedEncounter}
                  selectedEmr={props.selectedEmr}
                  selectedProgressCycle={props.selectedProgressCycle}
                  // selectedDrugItem to edit
                  selectedDrugItemDetail={props.selectedDrugItemDetail}
                  selectedDrugItemDetailOptions={props.selectedDrugItemDetailOptions}
                  drugSearchText={props.drugSearchText}
                  drugSearchResult={props.drugSearchResult}
                  drugSearchLoading={props.drugSearchLoading}
                  DrugSelectSequence={props.DrugSelectSequence}
                  verbalDrugOrderList={props.verbalDrugOrderList}
                  selectedDrug={props.selectedDrug}
                  drugOrderNotification={props.drugOrderNotification}
                  drugOrderList={props.drugOrderList}
                  drugOrderListLoading={props.drugOrderListLoading}
                  drugOrder={props.drugOrder}
                  renderDrugOrderItems={props.renderDrugOrderItems}
                  drugDose={props.drugDose}
                  drugQty={props.drugQty}
                  drugDuration={props.drugDuration}
                  drugDescription={props.drugDescription}
                  drugPrn={props.drugPrn}
                  // modal doctor certificate check
                  modDoctorCertificate={props.modDoctorCertificate}
                  DrugOrderTemplateSequence={props.DrugOrderTemplateSequence}
                  modConfirmDeleteDrug={props.modConfirmDeleteDrug}
                  modReMedication={props.modReMedication}
                  modDrugLabInteraction={props.modDrugLabInteraction}
                  modDrugDisease={props.modDrugDisease}
                  modDrugInteraction={props.modDrugInteraction}
                  modRecontinueMed={props.modRecontinueMed}
                  modAllVerifyDrug={props.modAllVerifyDrug}
                  modDuplicateDrug={props.modDuplicateDrug}
                  pediatricData={props.pediatricData}
                  searchedItemListWithKey={props.searchedItemListWithKey}
                  currentDoctor={props.currentDoctor}
                  drugOrderItemEstimate={props.drugOrderItemEstimate}
                  drugPermission={props.drugPermission}
                  searchedItemList={props.searchedItemList}
                  drugOrderItemMasterData={{
                    masterUnits: props.masterOptions.unit,
                    masterRoutes: props.masterOptions.route,
                    masterSites: props.masterOptions.site,
                    masterFrequencies: props.masterOptions.frequency,
                    masterMethods: props.masterOptions.method,
                  }}
                  duplicateReasonsOptions={props.masterOptions.duplicateReasons}
                  divisionForOrderDivOptions={props.masterOptions.divisionForOrderDiv}
                  masterData={props.masterData}
                  // SolventSelectionConfig
                  modSolventSelectionConfig={props.modSolventSelectionConfig}
                  selectedVerbalDrugOrderWorking={props.selectedVerbalDrugOrderWorking}
                  // Issue 57255
                  selectedDivision={props.selectedDivision}
                  // DrugOrderHistory
                  DrugOrderHistorySequence={props.DrugOrderHistorySequence}
                  drugHistoryList={props.drugHistoryList}
                  drugHistoryOrderList={props.drugHistoryOrderList}
                  drugOrderHistoryController={props.drugOrderHistoryController}
                  loadingStatus={props.loadingStatus}
                  //MedReconcile
                  medReconcileCheck={props.medReconcileCheck}
                  divisionType={props.divisionType}
                  medReconcileIndex={props.medReconcileIndex}
                  django={props.django}
                  drug
                  package
                  alert
                  drugPackageAlertList={props.drugPackageAlertList}
                  isStudentUser={props.isStudentUser}
                  masterOptions={props.masterOptions}
                  // เมื่อมีการกด edit มาจากรายการ drug tab order summary
                  drugOrderEdit={props.drugOrderEdit}
                  // เมื่อมีการกด Med Icon มาจากรายการ Medreconcile เพื่อเปิด Med Order
                  drugMedReconcileEditId={props.drugMedReconcileEditId}
                  openModPharmacistNote={props.openModPharmacistNote}
                  // CardDrugVerbalOrder
                  drugVerbalFreeTxt={props.drugVerbalFreeTxt}
                  disabledDrugType={!!editOrder}
                  onCertificateApprove={handleSaveVerbalOrder}
                  onDuplicateApprove={handleSaveVerbalOrder}
                  setIsDrugRequested={setIsDrugRequested}
                />
              </div>
            ) : containerName === "admit" ? (
              <div id="CardAdmitOrder" style={{ width: "100%" }}>
                <CardAdmitOrder
                  controller={props.crdAdmitOrderController}
                  orderId={editOrder?.ref_order}
                  subICDController={props.subICDController}
                  DJANGO={props.django}
                  PATIENT_DATA={props.PATIENT_DATA}
                  showHeader={false}
                  allowClear={true}
                  hideSaveButton={false}
                  hideCancelButton={false}
                  closeable={false}
                  toggleable={false}
                  hideBottom={true}
                  labelFitContent={true}
                  verbalOrderSave={verbalOrderSave}
                  orderByName={orderByName}
                  onSaveVerbalOrder={handleSaveAdmitOrder}
                  languageUX={props.languageUX}
                />
              </div>
            ) : containerName === "treatment" ? (
              <div style={{ width: "100%" }}>
                <CardTreatmentOrder
                  setProp={props.setProp}
                  onEvent={props.onEvent}
                  runSequence={props.runSequence}
                  isVerbalOrder={true}
                  claimOptions={props.masterOptions.claimTreatment}
                  OrderTreatmentSequence={props.OrderTreatmentSequence}
                  searchedItemList={props.searchedItemList}
                  treatmentOrderList={props.treatmentOrderList}
                  successMessage={props.successMessage}
                  errorMessage={props.errorMessage}
                  hideSaveButton={true}
                  procedureItems={props.procedureItems}
                  secondaryItems={props.secondaryItems}
                  treatmentTemplateList={props.treatmentTemplateList}
                  selectedProgressCycle={props.selectedProgressCycle}
                  //MedReconcile
                  medReconcileCheck={props.medReconcileCheck}
                  divisionType={props.divisionType}
                  medReconcileIndex={props.medReconcileIndex}
                  django={props.django}
                  selectedEncounter={props.selectedEncounter}
                  setEditItem={handleEditVerbalOrder}
                  // config
                  hidePreOrder={true}
                  languageUX={props.languageUX}
                />
              </div>
            ) : containerName === "imaging" ? (
              <div style={{ width: "100%" }}>
                <CardImagingOrder
                  // function
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  // seq
                  runSequence={props.runSequence}
                  ImagingOrderSequence={props.ImagingOrderSequence}
                  // data
                  orderId={selectedImagingId || null}
                  // CommonInterface
                  searchedItemListWithKey={props.searchedItemListWithKey}
                  buttonLoadCheck={props.buttonLoadCheck}
                  // selectedEncounter={props.selectedEncounter}
                  userTokenize={props.userTokenize}
                  errorMessage={props.errorMessage}
                  modXrayDetail={props.modXrayDetail}
                  // options
                  masterOptions={props.masterOptions}
                  // config
                  editMode={true}
                  hideButtonSave={true}
                  // ----- Dental Diagram
                  clinicalFindingId={props.clinicalFindingId}
                  clinicalFindingIndex={props.clinicalFindingIndex}
                  clinicalFindings={props.clinicalFindingList || []}
                  organ={props.organ}
                  clinicaltags={props.clinicaltags}
                  filterClinicalFindingIds={props.filterClinicalFindingIds || []}
                  // -----#
                  languageUX={props.languageUX}
                />
              </div>
            ) : containerName === "drnote" ? (
              <div style={{ width: "100%" }}>
                <CardDoctorNoteOrder
                  // function
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  // permission
                  drNoteOrderPermission={props.drNoteOrderPermission}
                  // CommonInterface
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  buttonLoadCheck={props.buttonLoadCheck}
                  // seq
                  runSequence={props.runSequence}
                  DoctorNoteOrderSequence={props.DoctorNoteOrderSequence}
                  // options
                  drNoteDivisionOptions={props.drNoteDivisionOptions}
                  // data
                  DJANGO={props.django}
                  hideButtonSave={true}
                  createAppointment={false}
                  hideContinuousCommand={props.selectedEncounter?.type === "OPD"}
                  // modPlanning
                  modPlanningConfig={props.modPlanningConfig}
                  planningData={props.planningData}
                  //MedReconcile
                  medReconcileCheck={props.medReconcileCheck}
                  divisionType={props.divisionType}
                  medReconcileIndex={props.medReconcileIndex}
                  django={props.django}
                  selectedEncounter={props.selectedEncounter}
                  languageUX={props.languageUX}
                />
              </div>
            ) : containerName === "history" ? (
              <div style={{ width: "100%" }}>
                <CardVerbalOrderHistory
                  actionable={true}
                  setProp={props.setProp}
                  onEvent={props.onEvent}
                  runSequence={props.runSequence}
                  searchedItemList={props.searchedItemList}
                  buttonLoadCheck={props.buttonLoadCheck}
                  loadingStatus={props.loadingStatus}
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  selectedEncounter={props.selectedEncounter}
                  setEditItem={handleEditVerbalOrder}
                  verbalOrderHistoryFilter={props.verbalOrderHistoryFilter}
                  verbalOrderHistoryList={props.verbalOrderHistoryList}
                  promptMessage={props.promptMessage}
                  duplicateLabDetected={props.duplicateLabDetected}
                  languageUX={props.languageUX}
                />
              </div>
            ) : (
              <div></div>
            )}
          </div>
        }
        languageUX={props.languageUX}
      />

      <ModMedReconcileAlert
        onEvent={props.onEvent}
        setProp={props.setProp}
        divisionType={props.divisionType}
        django={props.django}
        medReconcileCheck={props.medReconcileCheck}
        medReconcileIndex={props.medReconcileIndex}
        open={props.medReconcileCheck?.open_card_med_reconciliation}
        selectedEncounter={props.selectedEncounter}
        languageUX={props.languageUX}
      />

      <ModConfirm
        openModal={openConfirm}
        titleName={intl.formatMessage({ id: "กรุณายืนยัน" })}
        // @ts-ignore
        content={
          <>
            {props.promptMessage?.length === 2 ? (
              <>
                {props.promptMessage?.[0]} <br />
                <div dangerouslySetInnerHTML={{ __html: props.promptMessage?.[1] }} />
              </>
            ) : (
              props.promptMessage
            )}
          </>
        }
        onApprove={handleSaveDupLab}
        onDeny={handleCloseModConfirm}
      />

      <ModLockExpense
        onEvent={props.onEvent}
        setProp={props.setProp}
        buttonLoadCheck={props.buttonLoadCheck}
        errorMessage={props.errorMessage}
      />
    </div>
  );
};

export default React.memo(CardVerbalOrder);
