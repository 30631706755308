import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Dropdown,
  Input,
  Radio
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardTriageUX = (props: any) => {
    return(
      <div
        style={{width: "100%", height: "100%" }}>
        <div
          style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(20, 1fr)","gridTemplateColumns":"repeat(20, 1fr)"}}>
          <div
            style={{ gridRow: "2/3", gridColumn: "2/6",  }}>
            Chief Complaint
          </div>
          <div
            style={{ gridRow: "2/3", gridColumn: "7/20",  }}>
            <div
              id="txt-chiefComplaint"
              style={{border:"1px solid rgb(221, 221, 221)",borderRadius:"3px",padding:"8px"}}>
              {props.chiefComplaint}
            </div>
          </div>
          <div
            style={{"gridRow": props.hideDrugAllergyHistory ? "9/10" : "19/20","gridColumn":"16/20"}}>
            {props.buttonSave}
          </div>
          <div
            style={{ gridRow: "3/4", gridColumn: "2/6", marginTop:"5px"}}>
            สถานะการมา
          </div>
          <div
            style={{ gridRow: "4/5", gridColumn: "2/6",marginTop:"5px"}}>
            Arrived By
          </div>
          <div
            style={{ gridRow: "5/6", gridColumn: "2/6",marginTop:"5px" }}>
            ประเภทผู้ป่วย
          </div>
          <div
            style={{ gridRow: "6/7", gridColumn: "2/6",marginTop:"5px"}}>
            ข้อมูลผู้นำส่ง
          </div>
          <div
            style={{ gridRow: "7/8", gridColumn: "2/6",marginTop:"5px" }}>
            ระดับการคัดกรอง
          </div>
          <div
            style={{ gridRow: "3/4", gridColumn: "7/20", }}>
            <Dropdown
              fluid={true}
              id="cb-arriveStatus"
              onChange={props.changeTriage("patient_type")}
              options={props.patientTypeOptions}
              selection={true}
              style={{marginTop:"5px"}}
              value={props.patient_type}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "4/5", gridColumn: "7/20", }}>
            <Dropdown
              fluid={true}
              id="cb-arriveBy"
              onChange={props.changeTriage("arrive_status")}
              options={props.arriveStatusOptions}
              selection={true}
              style={{marginTop:"5px"}}
              value={props.arrive_status}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "5/6", gridColumn: "7/20", }}>
            <Dropdown
              fluid={true}
              id="cb-patientType"
              onChange={props.changeTriage("case")}
              options={props.caseOptions}
              selection={true}
              style={{marginTop:"5px"}}
              value={props.case}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "6/7", gridColumn: "7/20",}}>
            <Input
              fluid={true}
              id="txt-referer"
              onChange={props.changeTriage("referer")}
              style={{marginTop:"5px"}}
              value={props.referer}>
            </Input>
          </div>
          <div
            style={{ gridRow: "7/8", gridColumn: "7/20", }}>
            <Dropdown
              fluid={true}
              id="cb-triageLevel"
              onChange={props.changeTriage("triage_level")}
              options={props.triageLevelOptions}
              selection={true}
              style={{marginTop:"5px"}}
              value={props.triage_level}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "9/10", gridColumn: "2/10", display: props.hideDrugAllergyHistory ? "none" : ""}}>
            ประวัติการแพ้ยา
          </div>
          <div
            style={{"gridRow":"16/17","gridColumn":"2/3",display: props.hideDrugAllergyHistory ? "none" : ""}}>
            <Radio
              checked={props.action === "NOT_KNOW"}
              onChange={props.changeAdr("action", "NOT_KNOW")}>
            </Radio>
          </div>
          <div
            style={{"gridRow":"16/17","gridColumn":"3/7", display: props.hideDrugAllergyHistory ? "none" : ""}}>
            ไม่ทราบประวัติ
          </div>
          <div
            style={{"gridRow":"16/17","gridColumn":"8/9", display: props.hideDrugAllergyHistory ? "none" : ""}}>
            <Radio
              checked={props.action === "NO_REACTION"}
              onChange={props.changeAdr("action", "NO_REACTION")}>
            </Radio>
          </div>
          <div
            style={{"gridRow":"16/17","gridColumn":"9/13", display: props.hideDrugAllergyHistory ? "none" : ""}}>
            ไม่เคยมีประวัติแพ้
          </div>
          <div
            style={{"gridRow":"16/17","gridColumn":"14/15", display: props.hideDrugAllergyHistory ? "none" : ""}}>
            <Radio
              checked={props.action === "NOTE"}
              onChange={props.changeAdr("action", "NOTE")}>
            </Radio>
          </div>
          <div
            style={{"gridRow":"16/17","gridColumn":"15/20", display: props.hideDrugAllergyHistory ? "none" : ""}}>
            แพ้ยา / อื่นๆ
          </div>
          <div
            style={{ display: props.hideDrugAllergyHistory ? "none" : props.action === "NOTE" ? "block" : "", "gridRow":"17/18","gridColumn":"2/20"}}>
            <Input
              fluid={true}
              onChange={props.changeAdr("note")}
              placeholder="รายละเอีดการแพ้ยา"
              value={props.note}>
            </Input>
          </div>
          <div
            style={{ gridRow: "10/15", gridColumn: "2/20", display: props.hideDrugAllergyHistory ? "none" : "" }}>
            <Table
              data={props.adrList}
              headers="Status,Detail, User, Time"
              keys="status,note,edit_user,edited"
              minRows="4"
              showPagination={false}
              style={{height: "180px"}}>
            </Table>
          </div>
          <div
            style={{ paddingTop: "8px", gridRow: "15/16", gridColumn: "2/10", display: props.hideDrugAllergyHistory ? "none" : ""}}>
            เพิ่มประวัติแพ้ยา
          </div>
        </div>
      </div>
    )
}


export default CardTriageUX

export const screenPropsDefault = {"action":"NOTE"}

/* Date Time : Wed Jan 22 2025 16:07:59 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "label": "divgrid",
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(20, 1fr)\",\"gridTemplateColumns\":\"repeat(20, 1fr)\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Chief Complaint"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"2/6\",  }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"7/20\",  }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\": props.hideDrugAllergyHistory ? \"9/10\" : \"19/20\",\"gridColumn\":\"16/20\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะการมา"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"2/6\", marginTop:\"5px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Arrived By"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"2/6\",marginTop:\"5px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"2/6\",marginTop:\"5px\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อมูลผู้นำส่ง"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"2/6\",marginTop:\"5px\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ระดับการคัดกรอง"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"2/6\",marginTop:\"5px\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"7/20\", }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"7/20\", }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"7/20\", }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"7/20\",}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"7/20\", }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Dropdown",
      "parent": 12,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-arriveStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeTriage(\"patient_type\")"
        },
        "options": {
          "type": "code",
          "value": "props.patientTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient_type"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Dropdown",
      "parent": 13,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-arriveBy"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeTriage(\"arrive_status\")"
        },
        "options": {
          "type": "code",
          "value": "props.arriveStatusOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.arrive_status"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Dropdown",
      "parent": 14,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-patientType"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeTriage(\"case\")"
        },
        "options": {
          "type": "code",
          "value": "props.caseOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.case"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Dropdown",
      "parent": 16,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-triageLevel"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeTriage(\"triage_level\")"
        },
        "options": {
          "type": "code",
          "value": "props.triageLevelOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.triage_level"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Input",
      "parent": 15,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "txt-referer"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeTriage(\"referer\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.referer"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการแพ้ยา"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"2/10\", display: props.hideDrugAllergyHistory ? \"none\" : \"\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"16/17\",\"gridColumn\":\"2/3\",display: props.hideDrugAllergyHistory ? \"none\" : \"\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ไม่ทราบประวัติ"
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"16/17\",\"gridColumn\":\"3/7\", display: props.hideDrugAllergyHistory ? \"none\" : \"\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"16/17\",\"gridColumn\":\"8/9\", display: props.hideDrugAllergyHistory ? \"none\" : \"\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ไม่เคยมีประวัติแพ้"
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"16/17\",\"gridColumn\":\"9/13\", display: props.hideDrugAllergyHistory ? \"none\" : \"\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"16/17\",\"gridColumn\":\"14/15\", display: props.hideDrugAllergyHistory ? \"none\" : \"\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "แพ้ยา / อื่นๆ"
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"16/17\",\"gridColumn\":\"15/20\", display: props.hideDrugAllergyHistory ? \"none\" : \"\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: props.hideDrugAllergyHistory ? \"none\" : props.action === \"NOTE\" ? \"block\" : \"\", \"gridRow\":\"17/18\",\"gridColumn\":\"2/20\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Radio",
      "parent": 23,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.action === \"NOT_KNOW\""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAdr(\"action\", \"NOT_KNOW\")"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Radio",
      "parent": 25,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.action === \"NO_REACTION\""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAdr(\"action\", \"NO_REACTION\")"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Radio",
      "parent": 27,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.action === \"NOTE\""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAdr(\"action\", \"NOTE\")"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Input",
      "parent": 29,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAdr(\"note\")"
        },
        "placeholder": {
          "type": "value",
          "value": "รายละเอีดการแพ้ยา"
        },
        "value": {
          "type": "code",
          "value": "props.note"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/15\", gridColumn: \"2/20\", display: props.hideDrugAllergyHistory ? \"none\" : \"\" }"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มประวัติแพ้ยา"
        },
        "style": {
          "type": "code",
          "value": "{ paddingTop: \"8px\", gridRow: \"15/16\", gridColumn: \"2/10\", display: props.hideDrugAllergyHistory ? \"none\" : \"\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 36,
      "name": "Table",
      "parent": 34,
      "props": {
        "data": {
          "type": "code",
          "value": "props.adrList"
        },
        "headers": {
          "type": "value",
          "value": "Status,Detail, User, Time"
        },
        "keys": {
          "type": "value",
          "value": "status,note,edit_user,edited"
        },
        "minRows": {
          "type": "value",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"180px\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.chiefComplaint"
        },
        "id": {
          "type": "value",
          "value": "txt-chiefComplaint"
        },
        "style": {
          "type": "code",
          "value": "{border:\"1px solid rgb(221, 221, 221)\",borderRadius:\"3px\",padding:\"8px\"}"
        }
      },
      "seq": 37,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardTriageUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "action": "NOTE"
  },
  "width": 65
}

*********************************************************************************** */
